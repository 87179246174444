import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/2SN";
import Footer from "../../component/footer/2SN_Footer";
import { FiCheck } from "react-icons/fi";
import DoctorAppointmentandHospitalMasonry from "./DoctorAppointmentandHospitalMasonry";

// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]

class DoctorAppointmentandHospitalPortfolioDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Doctor Appointment and Hospital Management' pageUrl='/doctor-appointment-and-hospital-management' metaTitle="Doctor Appointment and Hospital Management" metaImage="/assets/images/portfolio/dp-portfolio-24.jpg"
                    metaDescription="Book doctor and hospital appointments easily with our web-based appointment management system. View available slots, select date and time, and streamline your healthcare experience." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--18" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Doctor Appointment and Hospital Management</h2>
                                    <p>Web Application</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Doctor Appointment and Hospital Management</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, CodeIgniter</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title theme-gradient">Doctor Appointment and Hospital Management</h3>
                                        <p>The proposed project is a smart appointment booking system that provides patients or any user an easy way of booking a doctor’s as well as hospital's appointment online. This is a web based application that overcomes the issue of managing and booking appointments according to user’s choice or demands. The task sometimes becomes very tedious for the compounder or doctor himself in manually allotting appointments for the users as per their availability. Hence this project offers an effective solution where users can view various booking slots available and select the preferred date and time.</p>
                                        <p> This system also allows users to cancel their booking anytime. The system provides an additional feature of view the previous patient’s medical file as per the patient’s permission. In this doctor generate the patient prescription and patient share the prescription to multiple pharmacists. Only one pharmacist can accept this prescription and delivered this prescription to patient. Admin will be able to view all the platform user's view, their flow, and manage the users like doctor, hospital, pharmacist, patient etc.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Easy registration of the patient.</li>
                                            <li><FiCheck />Online booking</li>
                                            <li><FiCheck />Digitalization of your processes and flows.</li>
                                            <li><FiCheck />Without login you can easily search the available doctors on the platform</li>
                                            <li><FiCheck />Secure the patient's prescription and medical file.</li>
                                            <li><FiCheck />Any time, any doctors view the patient’s medical file and analyze about the patient.</li>
                                            <li><FiCheck />Track the medical reports.</li>
                                            <li><FiCheck />Easy cancellation and switch the patient from one doctor to onother.</li>
                                            <li><FiCheck />Multi language</li>
                                            <li><FiCheck />And many more….</li>

                                            {/* <li><FiCheck />Alerts and notifications.</li> */}

                                        </ul>



                                        {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <DoctorAppointmentandHospitalMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>

                    </div>
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default DoctorAppointmentandHospitalPortfolioDetails;
