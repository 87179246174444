import React, { Component } from "react";
import { Link } from 'react-router-dom';
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
const logoUrl = <img src="/assets/images/logo/2SN_Logo_transparent.png" alt="2SN" />;

// const SocialShare = [
//     {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
//     {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
//     {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
//     {Social: <FaTwitter /> , link: 'https://twitter.com/'},
// ]

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area footer-style-01 bg_color--6">
                    {/* Start Call to Action Area  */}
                    <div className="im-call-to-action-area ptb--70 im-separator">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                                    <div className="inner">
                                        <h2 className="text-white mb--0">Are You Ready? Let’s Build Something Together.</h2>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
                                    <div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
                                        <Link className="btn-default btn-large btn-border btn-opacity" to="/contact">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Call to Action Area  */}

                    {/* Start Footer Area  */}
                    <div className="footer-wrapper ptb--30">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-center">
                                    <div className="ft-text">
                                        <div className="logo">
                                            <Link to="/">
                                                {logoUrl}
                                            </Link>
                                            <p>Copyright 2024 2SN Healthcare Ltd. All Rights Reserved.</p>
                                        </div>
                                        {/* <p style={{fontSize: "12px"}}>Registered as a company with Companies House in England. Company number 10296437.</p> */}

                                    </div>
                                </div>

                                {/* Start Single Widget  */}
                                {/* <div className="col-lg-2 col-xl-2 offset-xl-1 col-md-6 col-sm-6 col-12 mt_mobile--40"> */}
                                <div className="col-lg-2  col-md-6 col-sm-6 d-flex justify-content-center col-12 mt_mobile--40">
                                    <div className="footer-link">
                                        <h4>Quick Link</h4>
                                        <ul className="ft-link">
                                            <li><Link to="/portfolio">Portfolio</Link></li>
                                            <li><Link to="/about">About Us</Link></li>
                                            <li><Link to="/gallery">Gallery</Link></li>
                                            <li><Link to="/our-team">Our Team</Link></li>
                                            {/* <li><Link to="/contact">Contact</Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}


                                {/* Start Single Widget  */}
                                <div className="col-lg-2 col-md-6 col-sm-6 d-flex justify-content-center col-12 mt_md--40 mt_sm--40">
                                    <div className="footer-link">
                                        <h4>Support</h4>
                                        <ul className="ft-link">
                                            <li><Link to="/faq">FAQ</Link></li>
                                            <li><Link to="/contact">Contact us</Link></li>
                                            <li><Link to="/about">About us</Link></li>
                                            <li><Link to="/technology">Technology</Link></li>

                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}
                                {/* Start Single Widget  */}
                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 d-flex justify-content-center mt_md--40 mt_sm--40">
                                    <div className="footer-link">
                                        <h4>Legal</h4>
                                        <ul className="ft-link">
                                            <li><Link to="/terms-condition">Terms & Conditions</Link></li>
                                            <li><Link to="/privacy-policy">Privacy policy</Link></li>
                                            <li><Link to="/cookies-policy">Cookies policy</Link></li>
                                            <li><Link to="/modern-slavery-act">Modern Slavery Act Policy</Link></li>

                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}

                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 d-flex justify-content-center mt_md--40 mt_sm--40" >
                                    <div className="footer-link ">
                                        <h4>Accreditations</h4>
                                        <div className="logo">
                                            {/* <Link to="/"> */}
                                            <img src="/assets/images/logo/footer-logos.jpg" alt="Cyber image" />
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>

                                {/* End Single Widget  */}

                            </div>
                        </div>
                    </div>
                    <p style={{ fontSize: "12px", textAlign: 'center', marginTop: '10px' }}>Registered as a company with Companies House in England. Company number 10296437.</p>
                    {/* <div className="title"> */}
                    {/* </div> */}
                    {/* End Footer Area  */}
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;