import React, { Component } from "react";
import Helmet from "../../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/2SN";
import Footer from "../../component/footer/2SN_Footer";
// import PortfolioMasonry from './PortfolioMasonry';
import { FiCheck } from "react-icons/fi";
// import CourierPortfolioMasonry from "./CourierMasonry";
import HospitalMasonry from "./HospitalMasonry";
// import Lightbox from "react-image-lightbox";

// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]
// const Portfolio_image1 = <img src="/assets/images/portfolio/portfolio-1.jpg" alt="React Creative Agency" />;
// const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-2.jpg" alt="React Creative Agency" />;
// const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-3.jpg" alt="React Creative Agency" />;
// const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-4.jpg" alt="React Creative Agency" />;
// const list = [
//     {
//         image: Portfolio_image1,
//         category: 'Development',
//         title: 'Web Design',
//         description: 'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.'
//     },
//     {
//         image: Portfolio_image2,
//         category: 'Product Design',
//         title: 'App Development',
//         description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
//     },
//     {
//         image: Portfolio_image3,
//         category: 'Design',
//         title: 'Photoshop',
//         description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
//     },
//     {
//         image: Portfolio_image4,
//         category: 'Shop',
//         title: 'Woocommerce',
//         description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
//     },]

class HospitalPortfolioDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            photoIndex: 0,
            isTouched: false
        }
        this.openModal = this.openModal.bind(this)
        const { photoIndex, isTouched } = this.state
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <Helmet pageTitle='Hospital Management || 2SN healthcare ltd' pageUrl='/hospital-chain-management-system' />

                <Header />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--120 bg_image bg_image--34" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Hospital Chain Management System (Secondary Care)</h2>
                                    <p>Web / Android / iOS Application</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web / Android / iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Hospital Management </h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>.NET (C#),Android, JAVA, iOS, Swift, AngularJS</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title theme-gradient">Hospital Chain Management System</h3>
                                        {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
                                        <p>Hospital chain management system is a powerhouse platform for the healthcare industry to build strong professional networks between doctos and hospitals. Hospital chain management system is a device agnostic platform that works seamlessly on any handheld device or computer. It enables doctors to securely apply for specific desired opportunites across locations, manage their professional profiles and network with other doctors. This system also comes built with customizable dashboards for hospital administrators to create and publish rotas, advertise jobs, verify confidential documents and approve payments.</p>
                                        <p>Hospital chain management system is a robust, integrated platform that has evolved over many years of understanding the core needs of hospital management by the people who have worked on the front line of the NHS hospital structure. It has matured with the advent of contemporary technologies and today is available as a lightweight, contemporary downloadable apps that both doctors and hospitals can use to effectively manage rotas, easily apply for open job positions and facilitate smooth payments.</p>

                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Useful for primary care staff including doctors, advanced nurse practitioners, practice nurses, clinical pharmacists, health care assistances and admin staff.</li>
                                            <li><FiCheck />Each Hospital will be given unique login and can manage their Hospital's profiles, overview of vacancies, obtain holistic views of staff and operations and much more</li>
                                            <li><FiCheck />Build your professional network</li>
                                            <li><FiCheck />Powers-up your entire recruitement lifecycle</li>
                                            <li><FiCheck />Profile management</li>
                                            <li><FiCheck />Rota management</li>
                                            <li><FiCheck />Store, Verify, Access and Share Documents.</li>
                                            <li><FiCheck />Payment approvals</li>
                                            <li><FiCheck />Smart colaboration</li>
                                            <li><FiCheck />Advertise jobs</li>
                                            <li><FiCheck />Leave Management</li>
                                            <li><FiCheck />Notifications in system</li>
                                            <li><FiCheck />Push notification on mobile and whatsapp</li>
                                            <li><FiCheck />Job search and resume development</li>
                                            <li><FiCheck />Interactive dashboard</li>
                                            <li><FiCheck />Makes finding available doctors a simple task by eliminating agencies</li>
                                            <li><FiCheck />Hospitals can communicate with doctors to assign rotas</li>
                                            <li><FiCheck />View and manage invoices. Track payments for completed shifts. View pending payments and effectively manage all accounting needs for the shifts assigned and/or completed.</li>
                                            <li><FiCheck />Cross communicate with hosptial admins, accounting staff and other doctors can to bring a cohesive work environment</li>



                                        </ul>



                                        {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <HospitalMasonry item="4" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
                    </div>
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default HospitalPortfolioDetails;
