import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiChevronUp, FiMail, FiCopy } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";


const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Medical Services',
        description: 'Providing sessional medical professional cover and medical supplies.',
        url: '/medical-service'
    },
    {
        icon: <FiLayers />,
        title: 'Training & Education',
        description: 'We are committed to educating and training and engage with students from many Universities and postgraduate training.',
        url: '/traning-and-education-service'
    },
    {
        icon: <FiUsers />,
        title: 'Digital Healthcare & Strategy Solutions',
        description: 'Digital solutions make it easier for users to access the services they need, which can lead to better health outcomes.',
        url: '/digital-healthcare-and-strategy-solustions-service'
    },
    {
        icon: <FiMonitor />,
        title: 'Research & Innovation',
        description: 'Our company welcomes involvement in research studies and innovation, participating regularly in many projects.',
        url: '/research-and-innovation-service'
    },
    {
        icon: <FiMail />,
        title: 'App Development & Tech',
        description: 'Clinician-led development of apps supporting the healthcare industry to improve patient care and outcomes.',
        url: '/app-development-service'
    },
    {
        icon: <FiCopy />,
        title: 'Medical Concierge & Private Healthcare',
        description: 'Supporting medical tourism and clients requiring bespoke service for their individual needs.',
        url: '/medical-concierge-and-private-healthcare-service'
    },
]


class CookiePolicy extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Cookies Policy || 2SN Helthcare ltd' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Cookies Policy'} image={'bg_image--21'} />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area pb--120 pt--60 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    {/* <span className="subtitle">What we can do for you</span>
                                    <h2>Our Main Services</h2> */}
                                    <p>We use cookies to enhance your browsing experience and to personalize the content and advertisements shown to you.</p>

                                </div>
                                <div>
                                    {/* <h3>Detailed Page:</h3> */}
                                    <h4>Introduction</h4>
                                    <p>Welcome to 2SN Healthcare Ltd. website ("https://2snhealthcare.co.uk/"). By accessing or using the Site, you agree to be bound by the terms and conditions set forth in this Agreement (the "Terms of Service" or "TOS"). If you do not wish to be bound by the TOS, you may not access some features of this site. 2SN reserves the right to change the TOS from time to time at its sole discretion. Your continued use of the Site following any changes to the TOS will be deemed to be your acceptance of those changes.</p>
                                    <h4>Miscellaneous</h4>
                                    <p>The material contained on this site and on the associated web pages is general information and is not intended to advice on any particular matter. Subscribers and readers should seek appropriate professional advice before acting on the basis of any information contained herein.</p>
                                    <p>2SN Healthcare, its Founders, Employees, Agents, Representatives, and the Authors expressly disclaim any and all liability to any person, whether a subscriber or not, in respect of anything and of the consequences of anything done or omitted to be done by any such person in reliance upon the contents of this site and associated web pages.</p>
                                    <h4>Privacy Policy</h4>
                                    <p>We value your privacy! We never share, lease, sell or otherwise disclose your personal information. Any information you share with 2SN Healthcare Ltd. is held with utmost care and will not be used in ways to which you have not consented. If you have any questions at all about our privacy policy, please send an email to us.</p>
                                    <h4>Resale or Disclosure of Information to Third Parties</h4>
                                    <p>2SN Healthcare Ltd. does not sell, rent, loan, trade or lease any personal information collected through our website, including contact forms, download requests, or email lists.</p>
                                    <h4>Cookies Policy</h4>
                                    <p>We use cookies to personalize content and ads, provide social media features, and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners.</p>
                                    <h4>Governing Law</h4>
                                    <p>The TOS shall be governed by and construed in accordance with the laws of England without giving effect to any principles of conflicts of law.</p>
                                    <h4>Contact Information</h4>
                                    <p>If you have any questions about the TOS, please contact us
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default CookiePolicy;