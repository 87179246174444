import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/2SN";
import Footer from "../../component/footer/2SN_Footer";
// import PortfolioMasonry from './PortfolioMasonry';
import { FiCheck } from "react-icons/fi";
// import CourierPortfolioMasonry from "./CourierMasonry";
import PrimaryCareMasonry from "./PrimaryCareMasonry";

// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]

class Primarycare extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Primary Care || 2SN healthcare ltd' pageUrl='/primary-care-management-system' metaTitle="Primary Care Management System" metaImage="/assets/images/portfolio/dp-portfolio-07.jpg"
                    metaDescription="Primary Care Management System is a platform for the healthcare industry to build strong professional networks between Administrators, Doctors, Advanced Nurse Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff." />

                <Header />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--2" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Primary Care Management System</h2>
                                    <p>Web / Android / iOS Application</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web / Android / iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Hospital Management </h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>.NET (C#),Android, JAVA, iOS, Swift, AngularJS</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title theme-gradient">Primary Care Management System</h3>
                                        {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
                                        <p>Primary Care Management System is a platform for the healthcare industry to build strong professional networks between GP Administrators, Doctors, Advanced Nurse Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff.</p>
                                        <p>This platform helps in hiring the medicos and provides primary care staff seamlessly using any handheld device or computer. It enables doctors to easily apply for specific desired opportunities across locations, manage their professional profiles and network with other doctors. This system also comes built with customizable dashboards for hospital administrators to create and publish rotas, advertise jobs, verify confidential documents and approve payments.</p>
                                        <p>Primary Care Management  System is a robust, integrated platform that has evolved over many years of understanding the core needs of hospital management by the people who have worked on the front line of the NHS hospital structure. Today it is available as a lightweight downloadable app that each user effectively to manage rota, easily apply for open job positions and facilitate smooth payments.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Hiring in Medical field made easy.</li>
                                            <li><FiCheck />Powers up your entire recruitment lifecycle.</li>
                                            <li><FiCheck />Profile management.</li>
                                            <li><FiCheck />Secure document management.</li>
                                            <li><FiCheck />Payment approvals.</li>
                                            <li><FiCheck />Smart collaboration.</li>
                                            <li><FiCheck />Advertise jobs.</li>
                                            <li><FiCheck />Leave Management.</li>
                                            <li><FiCheck />Notifications in system.</li>
                                            <li><FiCheck />Push notifications on mobile and WhatsApp.</li>
                                            <li><FiCheck />Job search and resume development.</li>
                                            <li><FiCheck />Interactive dashboard.</li>
                                            <li><FiCheck />Makes finding available doctors a simple task by eliminating agencies.</li>
                                            <li><FiCheck />View and manage invoices. Track payments for completed shifts.</li>
                                            <li><FiCheck />View pending payments and effectively manage all accounting needs for the shifts assigned and/or completed.</li>
                                            <li><FiCheck />Cross communicate with hospital, admins, accounting staff and other doctors can bring a cohesive work environment.</li>




                                        </ul>



                                        {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <PrimaryCareMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>

                    </div>
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Primarycare;
