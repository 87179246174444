// import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../header/2SN";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../footer/2SN_Footer";

class ClientS extends React.Component {
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="DR Sameer || 2SN healthcare ltd" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
              {/* Start Breadcrump Area */}
              <div className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
                                    <h2 className="title theme-gradient">Dr Sameer</h2>
                                    {/* <p>Fresh From The Press Discription</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
             {/* Start Blog Details */}
            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                               <h2>Dr Sameer Nakedar</h2>
                               <h2>MBBS MRCGP PGCert</h2>
                               <p>Dr Sameer Nakedar (MBBS, MRCGP, PCCert) is a fully medically qualified General Practitioner. He has a portfolio role, and alongside working as a Doctor, he utilises expertise and training in other industry areas. He is a fully registered with the GMC (General Medical Council) in the UK since 2010.</p>
                               <p>Dr Nakedar obtained his first medical degree from the world-renowned Bart’s and the London Medical School (University of London). He completed his Foundation training in Leicester, having had experience in many areas of medicine and surgery, including transplant surgery, chemical pathology, stroke and cardiovascular medicine. Following this, Dr Nakedar spent some years in speciality training with substantial training and experience in both elective and trauma sectors of orthopaedic and general surgery. Subsequently, Dr Nakedar underwent further training in General Practice, Obstetrics and Gynaecology, Psychiatry, Genitourinary medicine, and General medicine and achieved membership in the Royal College of General Practitioners (MRCGP) following this.</p>
                               <p>He has had a professional interest in digital solutions and app development throughout his career. He has been instrumental in developing bespoke products for clients using a combination of his insight into healthcare and business affiliations with technology providers.</p>
                               <p>Dr Nakedar also has portfolio interests in medical research projects, clinical governance, business process and service delivery and medical education and training.</p>
                               <p>He enjoys family time, country walks and travelling with family in his spare time.</p>  
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Details */}
            {/* Start Footer Style  */}
            <Footer />
                {/* <FooterTwo /> */}
            <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
            </Fragment>
        )
    }
}
export default ClientS;