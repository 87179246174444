import React from 'react';
// import { Link } from 'react-router-dom';
// import PageHelmet from "../component/common/Helmet";
// import Breadcrumb from "../elements/common/Breadcrumb";
// import ScrollToTop from 'react-scroll-up';
// import { FiChevronUp } from "react-icons/fi";
// import Header from "../component/header/Header";
// import Footer from "../component/footer/Footer";
import Slider from "react-slick";
// import PortfolioList from "../elements/portfolio/PortfolioList";
import { slickDot } from "../page-demo/script";

const Portfolio_image = <img src="/assets/images/brand/client_1.png" alt="Client Partner" />;
const Portfolio_image2 = <img src="/assets/images/brand/client_2.png" alt="Client_02 Partner" />;
const Portfolio_image3 = <img src="/assets/images/brand/client_3.png" alt="Client_03 Partner" />;
const Portfolio_image4 = <img src="/assets/images/brand/client_4.png" alt="Client_04 Partner" />;
// const Portfolio_image5 = <img src="/assets/images/brand/client_5.png" alt="Client_05 Partner" />;
// const Portfolio_image6 = <img src="/assets/images/brand/client_6.png" alt="Client_06 Partner" />;
const Portfolio_image7 = <img src="/assets/images/brand/client_7.png" alt="Client_07 Partner" />;
const Portfolio_image8 = <img src="/assets/images/brand/client_8.png" alt="Client_08 Partner" />;
// const Portfolio_image9 = <img src="/assets/images/brand/client_9.png" alt="Client_09 Partner" />;
const Portfolio_image10 = <img src="/assets/images/brand/client_10.png" alt="Client_10 Partner" />;
const Portfolio_image11 = <img src="/assets/images/brand/client_11.png" alt="Client_11 Partner" />;
const Portfolio_image12 = <img src="/assets/images/brand/client_12.png" alt="Client_12 Partner" />;
const Portfolio_image13 = <img src="/assets/images/brand/client_13.png" alt="Client_13 Partner" />;
const Portfolio_image14 = <img src="/assets/images/brand/client_14.png" alt="Client_14 Partner" />;
const Portfolio_image15 = <img src="/assets/images/brand/client_15.png" alt="Client_15 Partner" />;
const Portfolio_image16 = <img src="/assets/images/brand/client_16.png" alt="Client_16 Partner" />;
const Portfolio_image17 = <img src="/assets/images/brand/client_17.png" alt="Client_17 Partner" />;
const Portfolio_image18 = <img src="/assets/images/brand/client_18.png" alt="Client_18 Partner" />;
const Portfolio_image19 = <img src="/assets/images/brand/client_19.png" alt="Client_19 Partner" />;
const Portfolio_image20 = <img src="/assets/images/brand/client_20.png" alt="Client_20 Partner" />;
const Portfolio_image21 = <img src="/assets/images/brand/client_21.png" alt="Client_21 Partner" />;
const Portfolio_image22 = <img src="/assets/images/brand/client_22.png" alt="Client_22 Partner" />;
const Portfolio_image23 = <img src="/assets/images/brand/client_23.png" alt="Client_23 Partner" />;
const Portfolio_image24 = <img src="/assets/images/brand/client_24.png" alt="Client_24 Partner" />;
const Portfolio_image25 = <img src="/assets/images/brand/client_25.png" alt="Client_25 Partner" />;
const Portfolio_image26 = <img src="/assets/images/brand/client_26.png" alt="Client_26 Partner" />;
const Portfolio_image27 = <img src="/assets/images/brand/client_27.png" alt="Client_27 Partner" />;
const Portfolio_image28 = <img src="/assets/images/brand/client_28.png" alt="Client_28 Partner" />;


const list = [
    {
        image: Portfolio_image,

    },
    {
        image: Portfolio_image2,

    },
    {
        image: Portfolio_image3,

    },
    {
        image: Portfolio_image4,

    },
    // {
    //     image: Portfolio_image5,

    // },
    // {
    //     image: Portfolio_image6,

    // },
    // {
    //     image: Portfolio_image7,

    // },
    {
        image: Portfolio_image8,

    },
    // {
    //     image: Portfolio_image9,

    // },
    {
        image: Portfolio_image10,

    },
    {
        image: Portfolio_image11,

    },
    {
        image: Portfolio_image12,

    },
    {
        image: Portfolio_image13,

    },
    {
        image: Portfolio_image14,

    },
    {
        image: Portfolio_image15,

    },
    {
        image: Portfolio_image16,

    },
    {
        image: Portfolio_image17,

    },
    {
        image: Portfolio_image18,

    },
    {
        image: Portfolio_image19,

    },
    {
        image: Portfolio_image20,

    },
    {
        image: Portfolio_image21,

    },
    {
        image: Portfolio_image22,

    },
    {
        image: Portfolio_image23,

    },
    {
        image: Portfolio_image24,

    },
    {
        image: Portfolio_image25,

    },
    {
        image: Portfolio_image26,

    },
    {
        image: Portfolio_image27,

    },
    {
        image: Portfolio_image28,

    }
]

const PortfolioList2 = [
    {
        image: 'image-1',
        category: 'Development',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: ' Getting tickets to the big show'
    }
]

const Portfolio = () => {
    return (
        <>



            {/* Start Portfolio Area */}
            <div className="">
                <div className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12" >
                                <div className="slick-space-gutter--15  ">
                                    <Slider {...slickDot} autoplaySpeed='20' style={{ display: 'none' }} autoplay>
                                        {list.map((value, index) => (
                                            <div className="single_im_portfolio" key={index}>
                                                <div className="">
                                                    <div className="">
                                                        <div className="" style={{ width: "65%" }}>
                                                            {value.image}
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* End Page Wrapper  */}

            {/* Start Back To Top */}

        </>
    )
}

export default Portfolio;