import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FiMail } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
// import BrandTwo from "../elements/BrandTwo";
import Brand_logo from '../blocks/Brand_logo'
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";



class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Contact || 2SN healthcare ltd' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--10" data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact Us</h2>
                                    {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--60 bg_color--5">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 text-center">
                                    <span className="subtitle">How to reach us.</span>
                                    <h2 className="title">Contact Us</h2>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">

                            {/* Start Single Address  */}
                            {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact Phone Number</h4>
                                        <p><a href="tel:+444 555 666 777">+444 555 666 777</a></p>
                                        <p><a href="tel:+222 222 222 333">+222 222 222 333</a></p>
                                    </div>
                                </div>
                            </div> */}
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            {/* <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:info@2snhealthcare.co.uk"> info@2snhealthcare.co.uk</a></p>
                                    </div>
                                </div>
                            </div> */}
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        <p>Treetops, Billinge end road Blackburn UK BB26PT</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                {/* <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                            
                        <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                        />
                        </GoogleMapReact>
                    </div>
                </div> */}
                {/* End Contact Map  */}


                {/* Start Brand Area */}
                <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Our Experiences</span>
                                    <h2 className="title">Some of the companies and brands we’ve worked with.</h2>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12 mt--20">
                                <BrandTwo />
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-lg-12 mt--20">
                                <Brand_logo />
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </React.Fragment>
        )
    }
}
export default Contact