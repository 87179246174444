import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";


class DotNet extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='.NET || 2SN Healthcare ltd ' pageUrl='/dot-net' metaTitle=".NET" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Build powerful and scalable .NET applications with 2SN Healthcare ltd. Our team of experts provides top-notch development services using the latest technologies." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--25" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">.NET</h2>
                                    <p>If you care about resilient software architecture, good app performance and lead the market, you’re at the right place.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        {/* <h4 className="tilte">One Full</h4> */}
                                        <p>2SN Healthcare ltd specializes in .NET development for custom business applications with great practiced in the C#/.NET programming language. </p>
                                        <p>.NET platform with its ASP.NET development framework is one of the core technologies used for web application development in our company.</p>
                                        <p>2SN Healthcare ltd delivers full cycle services, from business analysis and requirement management to continuous support and maintenance of the deployed software.</p>
                                        <p>Our development capabilities allow our clients to safely trust 2SN Healthcare ltd with their most complex and ambitious .NET projects.</p>
                                        <p>2SN Healthcare ltd developer has great experience in custom enterprise applications development using different Microsoft development environments (C#, ASP .NET, VB .NET, WinForms and others).</p>
                                        <p>We apply experienced application development professionals (including Microsoft Certified specialists) who are ready to invest their knowledge to development and enhancement of solutions that we offer to our clients.</p>
                                        <h4 className="title">Our broad range of .NET expertise includes:</h4>

                                        <ul className="list-style--1">
                                            <li><FiCheck />.NET web based solution development</li>
                                            <li><FiCheck />Migration of both desktop and web applications to .NET platform</li>
                                            <li><FiCheck />.NET custom software development</li>
                                            <li><FiCheck />Mobile applications development (based on .NET Compact Framework)</li>
                                            <li><FiCheck />.NET application development consulting</li>
                                            <li><FiCheck />Web Services based .NET Application development</li>
                                        </ul>
                                        <h4 className="title">2SN Healthcare Ltd's  Microsoft .NET technology competencies include:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />ASP.Net 4.5 /4.0 / 3.5 / 2.0 / 1.1</li>
                                            <li><FiCheck />ASP.NET CORE 2.0 / 3.0 / 3.1 / 5.0 / 6.0 </li>
                                            <li><FiCheck />Web Forms, Web Services and Web Server Controls</li>
                                            <li><FiCheck />Win Forms</li>
                                            <li><FiCheck />LINQ</li>
                                            <li><FiCheck />AJAX</li>
                                            <li><FiCheck />ADO.NET</li>
                                            <li><FiCheck />XML / XSLT / Xpath</li>
                                            <li><FiCheck />COM Interoperability</li>
                                            <li><FiCheck />SQL Server 2014 / 2016 / 2017 / 2019</li>
                                            <li><FiCheck />Microsoft Visual Studio IDE 2013 / 2015 / 2019 / 2022 </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}

                {/* Start Page Wrapper */}
                {/* <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner"> */}
                {/* Start Single Area */}
                {/* <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                   <p>.NET platform with its ASP.NET development framework is one of the core technologies used for web application development in our company. Noorisys Technologies delivers full cycle services, from business analysis and requirement management to continuous support and maintenance of the deployed software.</p>
                                                   <p>Noorisys Technologies developer has great experience in custom enterprise applications development using different Microsoft development environments (C#, ASP .NET, VB .NET, .NET CORE 2.0, 3.0 WinForms and others).</p>
                                                   <p>We apply experienced application development professionals (including Microsoft Certified specialists) who are ready to invest their knowledge to development and enhancement of solutions that we offer to our clients.</p>
                                                </div>
                                            </div>
                                        </div> */}
                {/* End Single Area */}

                {/* Start Single Area */}
                {/* <div className="row sercice-details-content align-items-center pb--80">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h5 className="title">Our broad range of .NET expertise includes:</h5>
                                                    <ul className="list-style--1">
                                                        <li><FiCheck/>.NET web based solution development</li>
                                                        <li><FiCheck/>Migration of both desktop and web applications to .NET platform</li>
                                                        
                                                        <li><FiCheck/>Mobile applications development (based on .NET Compact Framework)</li>
                                                        <li><FiCheck/>.NET application development consulting</li>
                                                        
                                                    </ul>
                                                    <h5 className="title">Noorisys Technologies 's Microsoft .NET technology competencies include:</h5>
                                                    <h4 className="title">Our Working Process</h4>
                                                    <ul className="list-style--1">
                                                        <li><FiCheck/>ASP.Net 4.5 /4.0 / 3.5</li>
                                                        <li><FiCheck/>.NET CORE 3.0/ 2.0</li>
                                                        <li><FiCheck/>Web Forms, Web Services and Web Server Controls</li>
                                                        <li><FiCheck/>LINQ</li>
                                                        <li><FiCheck/>AJAX</li>
                                                        <li><FiCheck/>ADO.NET</li>
                                                        <li><FiCheck/>XML / XSLT / Xpath</li>
                                                        <li><FiCheck/>COM Interoperability</li>
                                                        <li><FiCheck/>SQL Server 2019 / 2014 / 2012 / 2008 </li>
                                                        <li><FiCheck/>Microsoft .Net Framework, Visual Studio.Net 2005 / 2008 / 2010 / 2012</li>
                                                    </ul>
                                                  
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative"> */}
                {/* <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images" /> */}
                {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                {/* </div>
                                            </div>
                                        </div> */}
                {/* End Single Area */}


                {/* </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Page Wrapper */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default DotNet;