import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
// import { Link } from "react-router-dom";
// import {Link} from "react-router-dom";
const healthcare_insurance_1 = "/assets/images/portfolio/healthcare-insurance/healthcare-insurance-1.png"
const healthcare_insurance_2 = "/assets/images/portfolio/healthcare-insurance/healthcare-insurance-2.png"
const healthcare_insurance_3 = "/assets/images/portfolio/healthcare-insurance/healthcare-insurance-3.png"
const healthcare_insurance_4 = "/assets/images/portfolio/healthcare-insurance/healthcare-insurance-4.png"
const PortfolioList = [
    {
        image: healthcare_insurance_1,
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: healthcare_insurance_2,
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: healthcare_insurance_3,
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: healthcare_insurance_4,
        category: 'Development',
        title: 'Getting tickets to the big show'
    },

]

class HealthcareMasonry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false
        };
    }
    render() {
        const { photoIndex, isOpen } = this.state;
        const { column } = this.props;
        const list = PortfolioList.slice(0, this.props.item);
        return (
            <React.Fragment>
                {list.map((value, index) => (
                    // <div className={`${column}`} key={index}>
                        <div className={`${column}`} key={index} >
                        {isOpen && (
                            <Lightbox
                                mainSrc={`${PortfolioList[photoIndex].image}`}
                                nextSrc={`${PortfolioList[(photoIndex + 1) % PortfolioList.length].image}`}
                                prevSrc={`${PortfolioList[(photoIndex + PortfolioList.length - 1) % PortfolioList.length].image}`}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + PortfolioList.length - 1) % PortfolioList.length
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % PortfolioList.length
                                    })
                                }
                            />
                        )}


                            <div className="im_portfolio" onClick={() => { this.setState({ isOpen: true, photoIndex: index }) }}>
                                <div className="thumbnail_inner">
                                    <div className="thumbnail">
                                        {/* <Link to="/portfolio-details"> */}
                                            {/* {value.image} */}
                                            <img src={value.image} alt={value.title} />
                                        {/* </Link> */}
                                    </div>
                                </div>
                                {/* <div className="content">
                                    <div className="inner">
                                        <div className="portfolio_heading">
                                            <div className="category_list">
                                                <Link to="/portfolio-details">{value.category}</Link>
                                            </div>
                                            <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>
                                        </div>
                                        <div className="portfolio_hover">
                                            <p>{value.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <Link className="transparent_link" to="/portfolio-details"></Link> */}
                                    </div>
                                </div>
                            // </div>
                ))}
            </React.Fragment>
        )
    }
}
export default HealthcareMasonry;