import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";


class Python extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Python || 2SN Healthcare ltd' pageUrl='/python' metaTitle="Python" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Get expert Python development services from 2SN Healthcare ltd. Our team of experts provides top-notch development services using the latest technologies." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--25" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Python</h2>
                                    <p>Python enables swift and quick task completion, allowing you to merge systems effectively  while handling bulk data at the same time.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        {/* <h4 className="tilte">One Full</h4> */}
                                        <p>Python is a very popular general-purpose interpreted, interactive, object-oriented, and high-level programming language. It supports functional and structured programming methods as well as OOP.</p>
                                        <p>Python can be used as a scripting language or can be compiled to byte-code for building large applications. It provides very high-level dynamic data types and supports dynamic type checking.</p>
                                        <p>At 2SN Healthcare ltd - our Python developers have been consistently exceeding the expectations of customers in every project. 2SN Healthcare ltd offer the services of Python developers and data scientists with 2-10 years of experience who ensure first-time-right code, on-time delivery, and high productivity. Our flexible services stretch from filling specific Python skill gaps to providing self-managed Python development teams.</p>
                                        <h4 className="title">Our broad range of Python expertise include:</h4>

                                        <ul className="list-style--1">
                                            <li><FiCheck />Custom Python web application development.</li>
                                            <li><FiCheck />Python dynamic website development.</li>
                                            <li><FiCheck />Mobile application development.</li>
                                            <li><FiCheck />Python integration services.</li>
                                            <li><FiCheck />Python SaaS development.</li>
                                            <li><FiCheck />Server-side development.</li>
                                            <li><FiCheck />Django development.</li>
                                            <li><FiCheck />Application maintenance and support.</li>
                                        </ul>
                                        <h4 className="title">Our Python solutions provide the following:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Python custom Landing Page Design.</li>
                                            <li><FiCheck />Python plugins creation and installation.</li>
                                            <li><FiCheck />Shipping and Payment modules development.</li>
                                            <li><FiCheck />Python upgrade service.</li>
                                            <li><FiCheck />Our Python consultants can assist you narrow your ecommerce choices.</li>
                                            <li><FiCheck />Python integration with another party application.</li>
                                            <li><FiCheck />Conversion from any present platform to Python e-commerce.</li>
                                            <li><FiCheck />Creating shopping carts using Python.</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Python;