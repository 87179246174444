import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/2SN";
import Footer from "../../component/footer/2SN_Footer";

import { FiCheck } from "react-icons/fi";
import DoctorPersonalMasonry from "./DoctorPersonalMasonry";


// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]

class DoctorPersonalPortfolioDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Doctor Personal Presentation' pageUrl='/doctor-personal-presentation' metaTitle="Doctor Personal Presentation" metaImage="/assets/images/portfolio/dp-portfolio-46.jpg"
                    metaDescription="This is a professional networking and management platform for healthcare professionals. Features include a job application, professional profile management, rota management, smart collaboration and client/service management." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--20" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Doctor Personal Presentation</h2>
                                    <p>Web Application</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Doctor Personal Presentation</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title theme-gradient">Doctor Personal Presentation</h3>
                                        <p>Doctor personal presentation is a powerhouse platform for the healthcare industry to build strong professional networks between doctos and hospitals. It enables doctors to securely apply for specific desired opportunites across locations, manage their professional profiles and network with other doctors. This system comes with customizable dashboards for healthcare workers, where they can manage thier services, clients, jobs activity, invoices, personal fils, notes, web article and many more.</p>
                                        <p>Doctor personal presentation system is a robust, integrated platform that has evolved over many years of understanding the core needs of healthcare workers by the people who have worked on the front line of the hospital structure. It has matured with the advent of contemporary technologies and today is available as a lightweight, contemporaryt downloadable files that healthcare workers can use to effectively manage rotas, easily manage for thier job and many more.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Useful for primary care staff including doctors, advanced nurse practitioners, practice nurses, clinical pharmacists, health care assistances and admin staff</li>
                                            <li><FiCheck />Build your professional network</li>
                                            <li><FiCheck />Powers-up your profile</li>
                                            <li><FiCheck />Rota management</li>
                                            <li><FiCheck />Smart colaboration system</li>
                                            <li><FiCheck />Manage events from calender</li>
                                            <li><FiCheck />Add and manage services</li>
                                            <li><FiCheck />Add and manage clients</li>
                                            <li><FiCheck />Add and manage new job/task with clients</li>
                                            {/* <li><FiCheck />Manage invoice for jobs with transaction details</li> */}
                                            <li><FiCheck />Add working files and manage them</li>
                                            <li><FiCheck />Add notes and download them for offline use</li>
                                            <li><FiCheck />Add web articles and read them</li>

                                        </ul>



                                        {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <DoctorPersonalMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>

                    </div>
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default DoctorPersonalPortfolioDetails;
