import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>"2SN Healthcare has revolutionized the way I receive medical care. I can now easily connect with my doctor from the comfort of my home. I highly recommend 2SN to anyone looking for a convenient healthcare experience." </p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span> Mr RD. </span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>"The App development team at 2SN has been a game-changer for my practice. Their custom-built apps have made my work streamlined. The telehealth services have helped me reach more patients and provide care also in remote areas" </p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Dr. TW. </span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>" The virtual consultation was seamless. Their EMR system has also made it easier for me to keep track of my medical history and receive reminders for my follow-up appointments. Thank you 2SN!"</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span> Dr. JK . </span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>"2SN Healthcare has been a lifesaver for me. I can now receive medical care from the comfort of my home. The EMR system has also made it easy for me to share my medical records with other healthcare providers. I highly recommend. " </p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Mr MB Nagar. </span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>"The team at 2SN healthcare has been fantastic. Their apps have made it easier for me to manage my practice and reach more patients. I highly recommend to any healthcare provider looking to improve their services."</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Dr. SR. </span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>" Their EMR system is user-friendly and helps me keep track of all my medical records in one place. I couldn't be happier with the services provided by 2SN."</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span> MA Momin </span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    {/* <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Jaffin </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>JON CUMMINS </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                                            </div>
                                        </div>
                                    </TabPanel> */}
                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/testimonial/one.png" alt="Testimonial 1" />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/testimonial/two.png" alt="Testimonial 2" />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/testimonial/three.png" alt="Testimonial 3" />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/testimonial/four.png" alt="Testimonial 4" />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/testimonial/five.png" alt="Testimonial 5" />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/testimonial/six.png" alt="Testimonial 6" />
                                                </div>
                                            </div>
                                        </Tab>
                                        {/* <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-7.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-8.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab> */}
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;