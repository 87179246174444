import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
// import { Link } from "react-router-dom";
// import {Link} from "react-router-dom";
const meditation_app_1 = "/assets/images/portfolio/medication-app/medication-app-1.jpg"
const meditation_app_2 = "/assets/images/portfolio/medication-app/medication-app-2.jpg"
// const doctor_appointment_3 = "/assets/images/portfolio/doctor-appointment/doctor-appointment-3.jpg"
// const diet_app_4 = "/assets/images/portfolio/diet-app/diet-app-4.jpg"
const PortfolioList = [
    {
        image: meditation_app_1,
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: meditation_app_2,
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    // {
    //     image: doctor_appointment_3,
    //     category: 'Development',
    //     title: 'Getting tickets to the big show'
    // },
    // {
    //     image: diet_app_4,
    //     category: 'Development',
    //     title: 'Getting tickets to the big show'
    // },

]

class MeditationAppMasonry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false
        };
    }
    render() {
        const { photoIndex, isOpen } = this.state;
        const { column } = this.props;
        const list = PortfolioList.slice(0, this.props.item);
        return (
            <React.Fragment>
                {list.map((value, index) => (
                    // <div className={`${column}`} key={index}>
                        <div className={`${column}`} key={index} >
                        {isOpen && (
                            <Lightbox
                                mainSrc={`${PortfolioList[photoIndex].image}`}
                                nextSrc={`${PortfolioList[(photoIndex + 1) % PortfolioList.length].image}`}
                                prevSrc={`${PortfolioList[(photoIndex + PortfolioList.length - 1) % PortfolioList.length].image}`}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + PortfolioList.length - 1) % PortfolioList.length
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % PortfolioList.length
                                    })
                                }
                            />
                        )}


                            <div className="im_portfolio" onClick={() => { this.setState({ isOpen: true, photoIndex: index }) }}>
                                <div className="thumbnail_inner">
                                    <div className="thumbnail">
                                        {/* <Link to="/portfolio-details"> */}
                                            {/* {value.image} */}
                                            <img src={value.image} alt={value.title} />
                                        {/* </Link> */}
                                    </div>
                                </div>
                                {/* <div className="content">
                                    <div className="inner">
                                        <div className="portfolio_heading">
                                            <div className="category_list">
                                                <Link to="/portfolio-details">{value.category}</Link>
                                            </div>
                                            <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>
                                        </div>
                                        <div className="portfolio_hover">
                                            <p>{value.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <Link className="transparent_link" to="/portfolio-details"></Link> */}
                                    </div>
                                </div>
                            // </div>
                ))}
            </React.Fragment>
        )
    }
}
export default MeditationAppMasonry;