import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/2SN";
import Footer from "../../component/footer/2SN_Footer";
// import PortfolioMasonry from './PortfolioMasonry';
import { FiCheck } from "react-icons/fi";
// import CourierPortfolioMasonry from "./CourierMasonry";
// import HospitalMasonry from "./HospitalMasonry";
import HealthcareMasonry from "./HealthcareMasonry";

// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]

class HealthcarePortfolioDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Healthcare Insurance || 2SN healthcare ltd ' pageUrl='/healthcare-insurance-marketplace' metaTitle="Healthcare Insurance Marketplace" metaImage="/assets/images/portfolio/dp-portfolio-06.jpg"
                    metaDescription="This Healthcare insurance marketplace allows patients to use their health insurance on the platform. Services include online appointments with Doctors, Hospitals, Technicians, etc." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--6" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className=" rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Healthcare Insurance Marketplace</h2>
                                    <p className="description">Web / Android/ iOS Application</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web / Android / iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Healthcare Insurance Marketplace</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, CodeIgniter, Android, JAVA, iOS, Swift</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title theme-gradient">Healthcare Insurance Marketplace</h3>
                                        <p>Healthcare insurance marketplace is a digital platform that allows the patient to take out connected health insurance allowing their loved ones to be treated without paying costs. Healthcare insurance marketplace is a Medical Health platform that allows taking Online appointment to any Providers (Doctor, Hospital, Technician, etc.). Healthcare insurance marketplace platforms directly connect to Patients with Doctors. Doctors and Hospitals can register on the platform and provide the Services. Patients can easily connect with platforms from any platform (Android / iOS / Web ), make appointments, and do Online Payment.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Easy registration for providers and patients</li>
                                            <li><FiCheck />Email verification and Forgot password</li>
                                            <li><FiCheck />Attractive dashboard for each Providers and Patient</li>
                                            <li><FiCheck />Online Teleconsultation</li>
                                            <li><FiCheck />Audio / Video appointment</li>
                                            <li><FiCheck />Physical Appointment</li>
                                            <li><FiCheck />Consult at patient home</li>
                                            <li><FiCheck />EMR management</li>
                                            <li><FiCheck />Insurance Subscriptions</li>
                                            <li><FiCheck />Insurance Package Marketplace</li>
                                            <li><FiCheck />Manage Relatives</li>
                                            <li><FiCheck />Claims management</li>
                                            <li><FiCheck />Secure online payment</li>
                                            <li><FiCheck />Offline payment</li>
                                            <li><FiCheck />Multi-currency</li>
                                            <li><FiCheck />Multi language</li>
                                            <li><FiCheck />Accounts and wallet</li>
                                            <li><FiCheck />Separate chat for each audio / video appointment</li>
                                            <li><FiCheck />Alerts and notifications</li>
                                            <li><FiCheck />Fully responsive design</li>
                                            <li><FiCheck />And many more….</li>


                                        </ul>



                                        {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <HealthcareMasonry item="4" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>

                    </div>
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default HealthcarePortfolioDetails;
