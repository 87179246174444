import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";
// import { slideSlick } from "../page-demo/script";
// import Slider from "react-slick";



class Reactnative extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='React Native || 2SN Healthcare ltd' pageUrl='/react-native' metaTitle="React Native" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Build high-performance mobile apps for iOS and Android using React Native with the expertise of 2SN Healthcare ltd." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--80 pb--190 bg_image bg_image--25" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">React Native</h2>
                                    <p>Use a little—or a lot. You can use React Native in your existing Android and iOS projects or you can create a whole new app from scratch.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <h4 className="title">Written in JavaScript—rendered with native code</h4>
                                        <p>React primitives render to native platform UI, meaning your app uses the same native platform APIs other apps do.</p>
                                        <p>Many platforms, one React. Create platform-specific versions of components so a single codebase can share code across platforms. With React Native, one team can maintain two platforms and share a common technology—React.</p>
                                        <h4 className="title">Native Development For Everyone</h4>
                                        <p>React Native lets you create truly native apps and doesn't compromise your users' experiences. It provides a core set of platform agnostic native components like View, Text, and Image that map directly to the platform’s native UI building blocks.</p>
                                        <h4 className="title">Seamless Cross-Platform</h4>
                                        <p>React components wrap existing native code and interact with native APIs via React’s declarative UI paradigm and JavaScript. This enables native app development for whole new teams of developers, and can let existing native teams work much faster.</p>
                                        <h4 className="title">Our React Native Services:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />React Native App Development for Android</li>
                                            <li><FiCheck />React Native App Development for iOS</li>
                                            <li><FiCheck />React Native Cross-platform App Development</li>
                                            <li><FiCheck />React Native Consulting</li>
                                            <li><FiCheck />Server-side APIs for Native Mobile Apps</li>
                                            <li><FiCheck /> App Migration to React Native</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}







                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Reactnative;