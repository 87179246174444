import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import FooterTwo from "../component/footer/2SN_Footer";
import { FiChevronUp} from "react-icons/fi";
import Header from "../component/header/2SN";
import TabThree from "../elements/tab/TabThree";
import Helmet from "../component/common/Helmet";


class DesignerPortfolio extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render() {
        return (
            <div>
                <Helmet pageTitle="Portfolio ||2SN Healthcare ltd" />
                {/* Start Header Area  */}
                <Header headerPosition="header--static" logo="symbol-dark" colorblack="color--black"/>
                {/* End Header Area  */}

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Portfolio</h2>
                                    <p>2SN Healthcare Ltd boasts a track record of creating disruptive software applications and platforms of our own and assisting other organisations in reaching their goals. Passionate about understanding customer needs, business trends, and current technologies to deliver cost-effectively innovative solutions to new problems</p>
                                    {/* <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />May 18, 2021</li>
                                        <li><FiUser />Fatima Asrafi</li>
                                        <li><FiMessageCircle />15 Comments</li>
                                        <li><FiHeart />Like</li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
                
                {/* Start Designer Portfolio  */}
                <div className="designer-portfolio-area ptb--120 bg_color--1">
                    <div className="wrapper plr--70 plr_sm--30 plr_md--30">
                        <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
                    </div>
                </div>
                {/* End Designer Portfolio  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </div>
        )
    }
}   

export default DesignerPortfolio
