// import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../header/2SN";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../footer/2SN_Footer";

class ClientSavina extends React.Component {
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="DR Sameer || 2SN healthcare ltd" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
               {/* Start Breadcrump Area */}
               <div className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
                                    <h2 className="title theme-gradient">Mrs Savina</h2>
                                    {/* <p>Fresh From The Press Discription</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
             {/* Start Blog Details */}
            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                               <h2>Mrs Savina Nakedar</h2>
                               <h2>BSc (Opt)</h2>
                               <p>Mrs Savina Nakedar (BSc) is a fully qualified optometrist. She is focused on perfection, quality and impression. She maintains a portfolio role in her career.</p>
                               <p>She completed her bachelor’s degree at Bradford University in the UK. She has since worked under well-known establishments, including Tesco and Vision Express. Savina has, on numerous occasions, received exceptional feedback from her peers and patients. She often is one of the highest performers for conversion rates in her sectors. She continually keeps up to date with her professional development and is fully registered with the GOC.</p>
                               <p>Through her interest in optical fashion and presentation, Savina has developed welfare in event planning, micro-organisation and clientele relations. </p>
                               <p>Savina believes in impression, and her efforts in ensuring stunning presentations have provided vital developments in the digital solutions market.</p>
                               <p>Her years of experience aid business strategy for our company, and she makes critical stakeholder decisions for strategic development and growth.</p>
                                <p>Savina believes in giving back to those in need and is involved with charity work with contributions to many ventures globally.</p>
                                <p>During her spare time, she enjoys exercising, travelling and meeting new people. She also enjoys spending time with her children and family, who inspire all her efforts and ventures.</p>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Details */}
            {/* Start Footer Style  */}
            <Footer />
                {/* <FooterTwo /> */}
            <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
            </Fragment>
        )
    }
}
export default ClientSavina;