import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";
// import { slideSlick } from "../page-demo/script";
// import Slider from "react-slick";


class Flutter extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Flutter || 2SN Healthcare ltd' pageUrl='/flutter' metaTitle="Flutter" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Create visually stunning and high-performance apps for Android and iOS with the expertise of 2SN Healthcare ltd by using Flutter." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--25" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Flutter</h2>
                                    <p>Google’s UI toolkit for building beautiful, natively compiled applications for mobile, web, and desktop from a single codebase.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--80 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <h4 className="title">Why Flutter ?</h4>
                                        <p>Paint your app to life in milliseconds with Stateful Hot Reload. Use a rich set of fully-customizable widgets to build native interfaces in minutes.</p>
                                        <p>Quickly ship features with a focus on native end-user experiences. Layered architecture allows for full customization, which results in incredibly fast rendering and expressive and flexible designs.</p>
                                        <p>Flutter’s widgets incorporate all critical platform differences such as scrolling, navigation, icons and fonts, and your Flutter code is compiled to native ARM machine code using Dart's native compilers.</p>
                                        <h4 className="title">Why choose 2SN Healthcare ltd ?</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />We are a team of designers, senior developers, usability architects, project managers and quality assurance testers.</li>
                                            <li><FiCheck />2SN Healthcare ltd has a broad Flutter application development experience and are skilled at complete life cycle development of projects, from conception to the delivery of the apps.</li>
                                            <li><FiCheck />We have huge expertise in developing bespoke apps that help you achieve the maximum return on investment.</li>
                                            <li><FiCheck />We have 30+ successful mobile app development projects under our belt</li>
                                        </ul>
                                        <h4 className="title">Why hire 2SN Healthcare ltd ?</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />More than 30 Flutter apps.</li>
                                            <li><FiCheck />Guaranteed approval on Play store.</li>
                                            <li><FiCheck />Several years of experience in the industry.</li>
                                            <li><FiCheck />Clean and modern UI designs.</li>
                                            <li><FiCheck />On time delivery of projects.</li>
                                            <li><FiCheck />Bug free app development.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}




                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Flutter;