import React, { Component } from "react";
// import { FiSend, FiPlay } from "react-icons/fi";

import { Link } from 'react-router-dom';
class About extends Component {
    render() {
        let title = 'Overview.',
            description = 'A company providing high-quality medical services and innovative healthcare app development solutions. 2SN Healthcare is committed to improving patient outcomes and advancing the healthcare industry globally.',
            description2 = 'When problems to solutions are needed, we trust in the value of expertise. Our organisation prides itself on working with others in strategic service partnerships toward a shared vision, harnessing the beauty of teamwork.';
        let description3 = "We have developed a value-based relationship with our clients by making their visions come true in the era of booming technology."
        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-2.jpg" alt="home_about" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/* <div className="icon">
                                            <FiPlay />
                                        </div> */}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <p className="description">{description3}</p>
                                        <div className="purchase-btn">
                                            <Link className="btn-transparent" to="/about">Read More</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;