import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";
// import { slideSlick } from "../page-demo/script";
// import Slider from "react-slick";

class Kotlin extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Kotlin || 2SN Healthcare ltd' pageUrl='/kotlin' metaTitle="Kotlin" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Develop high-performance, robust, and safe mobile applications with Kotlin by 2SN Healthcare ltd. Our team of experts uses this modern programming language to deliver efficient solutions." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--25" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Kotlin</h2>
                                    <p>One of the best choices for developing microservices, as well as data science applications.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p>Kotlin has gained an astounding amount of interest in the past four years after Google’s #IO17 announcement supporting it as an official language for Android apps. Kotlin can be used anywhere Java is used, but presently it is predominantly used for Android app development.</p>
                                        <p>Our Kotlin app development services are designed to deliver best results in the development of Android apps. 2SN Healthcare ltd provides result driven Kotlin application development services to upscale the profit. We offer customized services to start-ups, small businesses, and enterprises looking to create robust Android apps.</p>
                                        <h4 className="title">Why Choose Kotlin?</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Less chances of bugs.</li>
                                            <li><FiCheck />More reliable.</li>
                                            <li><FiCheck />Easy maintenance.</li>
                                            <li><FiCheck />Coordinate with existing java code</li>
                                            <li><FiCheck />High optimization.</li>
                                        </ul>
                                        <h4 className="title">We Offer Following Kotlin Services:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Kotlin app development services.</li>
                                            <li><FiCheck />Kotlin app design services</li>
                                            <li><FiCheck />Kotlin app migration.</li>
                                            <li><FiCheck />Kotlin app porting and upgradation.</li>
                                            <li><FiCheck />Kotlin app testing.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Kotlin;