import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/portfolio-2sn.png" alt="portfolio_1" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-2sn2.png" alt="portfolio_2" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-2sn3.png" alt="portfolio_3" />;

const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Development',
        title: 'Rapid Testing Verification',
        description: 'FasterChecks is a test verification platform that allows you to take Covid Antigen Test and get the certificate within 15 minutes.',
        url: '/rapid-testing-verification'
    },

    {
        image: Portfolio_image3,
        category: 'Application',
        title: 'Multilingual Pregnancy App',
        description: 'A multilingual mobile application that will help to solve common health conditions or problems a woman may experience during her pregnancy period.',
        url: '/multilingual-pregnancy-app'
    },
    {
        image: Portfolio_image2,
        category: 'Product Design',
        title: 'Hospital Chain Management',
        description: 'Hospital chain management system is a powerhouse platform for the healthcare industry to build strong professional networks between doctos and hospitals.',
        url: '/hospital-chain-management'
    }
]

class Portfolio extends Component {
    render() {
        let title = 'Some of our Recent Work',
            description = 'Discover the diverse range of industries we have impacted with our cutting-edge solutions ';
        return (
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Our projects</span>
                                    <h2 className="title">{title}</h2>
                                    <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            {PortfolioList.map((value, index) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={index}>
                                    <Link to={value.url}>
                                        <div className="im_portfolio">
                                            <div className="thumbnail_inner">
                                                <div className="thumbnail">
                                                    {value.image}
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <div className="portfolio_heading">
                                                        {/* <div className="category_list">
                                                        <Link to={value.url}>
                                                        {value.title}
                                                        </Link>
                                                    </div> */}
                                                        <h4 className="title">
                                                            {/* <Link to={value.url}> */}
                                                            {value.title}
                                                            {/* </Link> */}
                                                        </h4>
                                                    </div>
                                                    {/* <div className="portfolio_hover">
                                                    <p>{value.description}</p>
                                                </div> */}
                                                </div>
                                            </div>
                                            {/* <Link className="transparent_link" to="#"></Link> */}
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;