import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";





class FAQ extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='FAQ ||2SN healthcare ltd' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'FAQ'} image={'bg_image--29'} />
                {/* End Breadcrump Area */}
                <div className="service-area pt--60 pb--30 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    {/* <span className="subtitle">What we can do for you</span> */}
                                    <h2>Coming Soon</h2>
                                    <p>We Are working on it</p>
                                    {/* <p>Please see some of our services which we offer our customers globally.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Blog Details */}
                <div className="rn-blog-details  pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}




                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default FAQ;