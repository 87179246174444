import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import { FiChevronUp } from "react-icons/fi";
// import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";

// const Portfolio_image = <img src="/assets/images/portfolio/interior/portfolio-15.jpg" alt="React Creative Agency" />;
// const Portfolio_image2 = <img src="/assets/images/portfolio/interior/portfolio-12.jpg" alt="React Creative Agency" />;
// const Portfolio_image3 = <img src="/assets/images/portfolio/interior/portfolio-13.jpg" alt="React Creative Agency" />;
// const Portfolio_image4 = <img src="/assets/images/portfolio/interior/portfolio-14.jpg" alt="React Creative Agency" />;


const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'About Us',
        // description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
        // buttonText: 'Contact Us',
        // buttonLink: '/contact'
    }
]

// const PortfolioList = [
//     {
//         image: Portfolio_image,
//         category: 'Agency',
//         title: 'Agency Collection',
//         description: 'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit conse ctetur adipiscing elit.'
//     },
//     {
//         image: Portfolio_image2,
//         category: 'Home Design',
//         title: 'Office Managemnt',
//         description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit conse ctetur adipiscing elit.'
//     },
//     {
//         image: Portfolio_image3,
//         category: 'Company',
//         title: 'Company Inner',
//         description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit conse ctetur adipiscing elit.'
//     },
//     {
//         image: Portfolio_image4,
//         category: 'Office Interior',
//         title: 'Creative Agency',
//         description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit conse ctetur adipiscing elit.'
//     },
// ]

class HomeParticles extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader() { }


    render() {
        // var namesItemOne = [
        //     'The Philosophy Of business analytics',
        //     'Fast-Track Your business',
        //     'Lies And Damn Lies About business',
        // ];

        // const PostList = BlogContent.slice(0, 5);

        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        return (
            <Fragment>
                <Helmet pageTitle="About Us || 2SN healthcare ltd" />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','service', 'about', 'portfolio','team','testimonial','blog', 'contact']} currentClassName="is-current" offset={-200}>
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#service">Service</a></li>
                                    <li><a href="#about">About</a></li>
                                    <li><a href="#portfolio">Portfolio</a></li>
                                    <li><a href="#team">Team</a></li>
                                    <li><a href="#testimonial">Testimonial</a></li>
                                    <li><a href="#blog">Blog</a></li>
                                    <li><a href="#contact">Contact</a></li>
                                </Scrollspy>
                            </nav>
                            <div className="header-btn">
                                <a className="btn-default btn-border btn-opacity" target="_blank" href="https://themeforest.net/checkout/from_item/31405042?license=regular">
                                    <span>Buy Now</span>
                                </a>
                            </div>
                            Start Humberger Menu 
                            <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            End Humberger Menu 
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header> */}
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency with-particles" id="home">
                    <div className="frame-layout__particles">
                        <Particles className="particle"
                            options={{
                                style: {
                                    position: "absolute"
                                },
                                fpsLimit: 100,
                                interactivity: {
                                    detectsOn: "canvas",
                                    events: {
                                        onClick: {
                                            enable: true,
                                            mode: "push",
                                        },
                                        onHover: {
                                            enable: true,
                                            mode: "repulse",
                                        },
                                        resize: true,
                                    },
                                    modes: {
                                        bubble: {
                                            distance: 100,
                                            duration: 2,
                                            opacity: 0.8,
                                            size: 10,
                                            color: "#888",
                                        },
                                        push: {
                                            quantity: 4,
                                        },
                                        repulse: {
                                            distance: 100,
                                            duration: 0.4,
                                            color: "#888",
                                        },
                                    },
                                },
                                particles: {
                                    color: {
                                        value: "#888",
                                    },
                                    links: {
                                        color: "#888",
                                        distance: 150,
                                        enable: true,
                                        opacity: 0.5,
                                        width: 1,
                                    },
                                    collisions: {
                                        enable: true,
                                    },
                                    move: {
                                        direction: "none",
                                        enable: true,
                                        outMode: "bounce",
                                        random: false,
                                        speed: 6,
                                        straight: false,

                                    },
                                    number: {
                                        density: {
                                            enable: true,
                                            value_area: 2000,
                                        },
                                        value: 80,
                                    },
                                    opacity: {
                                        value: 0.5,
                                    },
                                    shape: {
                                        type: "circle",
                                    },
                                    size: {
                                        random: true,
                                        value: 5,
                                    },
                                },
                                detectRetina: true,
                            }}
                        />
                    </div>
                    <div className="im_modern_slider bg_image bg_image--27 ">
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                {/* {value.description ? <p className="description_style-2">{value.description}</p> : ''} */}
                                                {/* {value.buttonText ? <div className="slide-btn"><a className="btn-default btn-large" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>2SN Healthcare ltd started as a small medical company founded by a group of passionate healthcare professionals. Our journey began by seeing patients and providing the best care we could. However, as time passed, we saw the need for more efficient and cost-effective solutions in the healthcare industry. </p>
                                        <p>Our founders began exploring the world of digital healthcare and developing digital solutions and apps that could streamline the healthcare process and improve patient outcomes. They quickly discovered their passion for healthcare and technology was powerful</p>
                                        <p>With time, the company's activity grew, we took on more and more projects and our company started operating with customers globally. Alongside providing direct patient care, our company began to develop apps for doctors, clinics, nurses and patients used in hospitals and clinics across the globe. Being clinician-led, our unique insight and critical understanding of the industry allowed us to see digital solutions' positive impact on the healthcare industry.</p>
                                        <p>Our company has become a successful digital healthcare solutions and medical service provider. We continued to learn and grow with each new experience and build our expertise in the domain. Despite the challenges, our company always retained sight of our goal to provide the best patient care possible. We've continued to innovate and strive for excellence, always putting the needs of our clients and patients first. When problems to solutions are needed, we trust in the value of expertise. Our organisation prides itself on working with others in strategic service partnerships toward a shared vision, harnessing the beauty of teamwork. We have experienced successes in smaller projects of under £1000 and have even developed digital solutions that have scaled to multi-million pound turnovers and global operations. </p>
                                        <p>2SN Healthcare Ltd is now a reputed name in the industry, delivering digital solutions worldwide and helping healthcare professionals and patients easily navigate the complex world of healthcare. Through technology and professionalism, our company has helped medical tourism and healthcare concierge for high net-worth clientele facilitating access to some of the best care globally. </p>
                                        <p>We are committed to learning and growing and will continue sharing our knowledge and experience with others worldwide. We also support the research sectors and medical education and training. </p>
                                        <p>Through years of development and growth, at 2SN Healthcare ltd., we provide a range of medical and healthcare technology services. </p>
                                        <p>Please click here to see  <span>
                                            <Link className="btn-transparent" to="/services">Our Services</Link>
                                        </span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Footer Style  */}
                <Footer />
                {/* <FooterTwo /> */}
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default HomeParticles;