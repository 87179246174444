import React, { Component } from "react";
// import { FiCheck } from "react-icons/fi";
import PageHelmet from "../../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/2SN";
import Footer from "../../component/footer/2SN_Footer";
// import { Link } from 'react-router-dom';
import Country_flag from "../../blocks/Country_flag";


class ServiceDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Medical Concierge and Private Healthcare' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--4" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
                                    <h2 className="title theme-gradient">Medical Concierge and Private Healthcare</h2>
                                    {/* <p>Fresh From The Press Discription</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/Private_Helathcare_img2.png" alt="private health 1" />
                                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Our service</span> */}
                                                        <h2 className="title">Medical Concierge and Private Healthcare</h2>
                                                        <p className="description">Our medical concierge & private healthcare service is designed for individuals seeking bespoke assistance to find the right healthcare services for their needs. We understand that navigating the healthcare system in any country can be overwhelming and time-consuming. Our team of professionals can help you every step of the way.</p>
                                                        <p className="description">Our services are primarily based in the UK. However, with the growth of global medical tourism, our teams have assisted individuals from Europe, Africa, Australia, South Asia, Malaysia, and the Middle East. These customers have received care not only in the UK but in other countries as well.</p>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Our service</span>
                                                        <h2 className="title">Providing Service</h2> */}
                                                        <p className="description">We provide personalized assistance in finding the best healthcare providers and services to meet your needs. We'll help you find the right doctor, hospital or clinic; if needed, we can also aid with scheduling appointments. We can also assist patients by simply summarising their current situation and help make better understandings and informed decisions. Individual cases will always have unique pros and cons.</p>
                                                        <p className="description">Our concierge service is there to ensure you have a smooth and comfortable experience. We understand the importance of clear communication in healthcare and will work with you to ensure that you know all the information provided.</p>
                                                        <p className="description">We always put patients first, and our goal is to make your healthcare experience as stress-free and seamless as possible. We can also guide you through the practical steps of various additional services, such as arranging transportation and accommodation and providing information on local customs and dietary needs.</p>
                                                        <div className="purchase-btn">
                                                            {/* <Link className="btn-transparent" to="/">Please read some of our success stories.</Link> */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/Private_Helathcare_img3.png" alt="prvate 2 Images" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Brand Area */}
                                        <div className="rn-brand-area ptb--120 bg_color--1">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="section-title text-center mb--30">
                                                            {/* <span className="subtitle">Our Experiences</span> */}
                                                            <h2 className="title">Countries our concierge clients have come from and we’ve worked with.</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-lg-12 mt--20">
                                                        <BrandTwo />
                                                    </div>
                                                </div> */}

                                                <div className="row">
                                                    <div className="col-lg-12 mt--20">
                                                        <Country_flag />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Brand Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;