import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";
import { FiCheck } from "react-icons/fi";
// import { slideSlick } from "../page-demo/script";
// import Slider from "react-slick";

class Objectivec extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Objective C || 2SN Healthcare ltd' pageUrl='/objective-c' metaTitle="Objective C" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Develop high-performance and robust mobile apps for iOS with Objective-C by 2SN Healthcare ltd. Our team of experts uses this powerful, object-oriented programming language to deliver efficient solutions." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--25" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Objective C</h2>
                                    <p>Apple has been using Objective C since the late 1990s, wanna try it too?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p>Objective C is a superset of C programming language that adds messaging capabilities and it is the best choice for high performance mobile applications. It has great runtime with Apple systems and store applications.</p>
                                        <p>Objective C is great for memory management. The graphical manipulation is seamless while using the language. At 2SN Healthcare ltd, we provide robust Objective C mobile application solutions that help clients with unparalleled services. Our applications are detail-oriented, responsive and fast. Though Objective C has been replaced by Swift, it still has great value.</p>


                                        <h4 className="title">Why Choose Objective C To Build Your Mobile Application?</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Easy integration with third party APIs.</li>
                                            <li><FiCheck />Creates object-oriented functional apps.</li>
                                            <li><FiCheck />Rich features iOS applications.</li>
                                            <li><FiCheck />Great compatibility with C language.</li>
                                            <li><FiCheck />Dynamic runtime.</li>
                                            <li><FiCheck />Automatic garbage collection.</li>
                                            <li><FiCheck />Access to Apple technologies.</li>
                                        </ul>
                                        <h4 className="title">Our Areas of Expertise:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Custom Objective C Development.</li>
                                            <li><FiCheck />Objective C Software Modification.</li>
                                            <li><FiCheck />Enterprise Application Deployment.</li>
                                            <li><FiCheck />Apple Platform Configuration.</li>
                                            <li><FiCheck />App integration, Porting and Migration.</li>
                                            <li><FiCheck />Maintenance and Support. </li>
                                            <li><FiCheck />QA testing for Apple Devices.</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Objectivec;