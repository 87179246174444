import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiChevronUp, FiMail, FiCopy } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";
import ServiceTwo from "./service/2SN_Services";


const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Medical Services',
        description: 'Providing sessional medical professional cover and medical supplies.',
        url: '/medical-service'
    },
    {
        icon: <FiLayers />,
        title: 'Training & Education',
        description: 'We are committed to educating and training and engage with students from many Universities and postgraduate training.',
        url: '/traning-and-education-service'
    },
    {
        icon: <FiUsers />,
        title: 'Digital Healthcare & Strategy Solutions',
        description: 'Digital solutions make it easier for users to access the services they need, which can lead to better health outcomes.',
        url: '/digital-healthcare-and-strategy-solustions-service'
    },
    {
        icon: <FiMonitor />,
        title: 'Research & Innovation',
        description: 'Our company welcomes involvement in research studies and innovation, participating regularly in many projects.',
        url: '/research-and-innovation-service'
    },
    {
        icon: <FiMail />,
        title: 'App Development & Tech',
        description: 'Clinician-led development of apps supporting the healthcare industry to improve patient care and outcomes.',
        url: '/app-development-service'
    },
    {
        icon: <FiCopy />,
        title: 'Medical Concierge & Private Healthcare',
        description: 'Supporting medical tourism and clients requiring bespoke service for their individual needs.',
        url: '/medical-concierge-and-private-healthcare-service'
    },
]


class Service extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Service || 2SN healthcare ltd' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Services'} image={'bg_image--31'} />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                {/* <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">What we can do for you</span>
                                    <h2>Our Main Services</h2>
                                    <p>Please see some of our services which we offer our customers globally.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-main-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <Link to={val.url}>
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                <div className="service-area ptb--120  bg_color--5">
                    <ServiceTwo />
                </div>
                {/* End Service Area */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;