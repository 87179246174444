import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/2SN";
import Footer from "../../component/footer/2SN_Footer";
import { FiCheck } from "react-icons/fi";
import FasterChecksMasonry from "./FasterChecksMasonry";

// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]

class FasterChecksPortfolioDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Rapid Testing verification services' pageUrl='/faster-checks' metaTitle="Antigen Test Verification Platform" metaImage="/assets/images/portfolio/dp-portfolio-11.jpg"
                    metaDescription="This is a test verification platform that allows you to take Covid Antigen Test and get the certificate within 15 minutes. You can perform the antigen test whenever and wherever you want." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--12" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Rapid Testing Verification Services</h2>
                                    <p>Web / Android/ iOS Application</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web / Android/ iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Rapid Testing Platform</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, CodeIgniter, Angular, Android, JAVA, iOS, Swift</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title theme-gradient">Rapid Testing Verification Platform</h3>
                                        {/* <p>FasterChecks is a test verification platform that allows you to take Covid Antigen Test and get the certificate within 15 minutes. There is no need to get an appointment, go to the test center anymore, you can perform the antigen test whenever and wherever you want. After receiving the test kit from your test provider, register yourself on the web platform provided on your INSTRUCTION card. After signing up, you can continue on the web browser or if you are an Apple user, you can download the app from the App store. It is the quick method that allows you to perform the test, upload it using our platform and get the test certificate in less than half an hour, that’s it!</p> */}
                                         <p>We facilitated the creation of a test verification platform that allowed users to take Rapid Antigen Tests and get the certified results within 15 minutes. This solution reduced the need to get appointments and go to the test centres using advanced tools such as our custom-built Artificial Intelligence, along with manual verification. Users could safely perform the antigen test whenever and wherever they wanted. A solution was built for Apple users in the form of a custom app, and the complete web platform and backend facilitated a technology-agnostic audience. Over 1.5 million global users benefitted from this technology, and this supported governments and public health reporting for disease control and statistical data. </p>

                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />One stop solution for rapid testing</li>
                                            <li><FiCheck />Cross-platform application</li>
                                            <li><FiCheck />Suitable for all age groups</li>
                                            <li><FiCheck />Quick request processing</li>
                                            <li><FiCheck />Fully Responsive Design</li>
                                            <li><FiCheck />Attractive dashboard</li>
                                            <li><FiCheck />Easy registration</li>
                                            <li><FiCheck />QR code scanning</li>
                                            <li><FiCheck />Data Security</li>
                                            <li><FiCheck />Supportive Artificial Intelligence</li>
                                            <li><FiCheck />And many more….</li>


                                            {/* <li><FiCheck />Alerts and notifications.</li> */}

                                        </ul>



                                        {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <FasterChecksMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>

                    </div>
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default FasterChecksPortfolioDetails;
