import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";


class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded')
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    stickyHeader() { }
    render() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }



        const { color = 'default-color' } = this.props;
        // let logoUrl;
        // if (logo === 'light') {
        //     logoUrl = <img src="/assets/images/logo/two_sn_logo.png" alt="2SN logo" />;
        // } else if (logo === 'dark') {
        //     logoUrl = <img src="/assets/images/logo/two_sn_logo.png" alt="2SN logo" />;
        // } else if (logo === 'symbol-dark') {
        //     logoUrl = <img src="/assets/images/logo/two_sn_logo.png" alt="2SN logo" />;
        // } else if (logo === 'symbol-light') {
        //     logoUrl = <img src="/assets/images/logo/2SN_Logo_transparent.png" alt="2SN logo" />;
        // } else {
        //     logoUrl = <img src="/assets/images/logo/2SN_Logo_transparent.png" alt="Digital Agency" />;
        // }



        return (
            <header className={`header-area header--fixed formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <Link to='/'>
                                {/* {logoUrl} */}
                                <img className="logo-1" src="/assets/images/logo/2SN_Logo_transparent_1.png" alt="Logo Images" />
                                <img className="logo-2" src="/assets/images/logo/two_sn_logo.png" alt="Logo Images" />
                            </Link>
                        </div>
                    </div>
                    {/* <header className="header-area formobile-menu header--fixed default-color">
             <div className="header-wrapper" id="header-wrapper">
                 <div className="header-left">
                     <div className="logo">
                        <Link to="/">
                            <img className="logo-1" src="/assets/images/logo/2SN_Logo_transparent_1.png" alt="Logo Images"/>
                           <img className="logo-2" src="/assets/images/logo/two_sn_logo.png" alt="Logo Images"/>
                      </Link>
                    </div>
                </div> */}
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                {/* <li className="has-droupdown"><Link to="/">Home</Link> */}
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about" >About Us</Link></li>
                                <li><Link to="/services" >Services</Link></li>


                                <li><Link to="/portfolio" >Portfolio</Link></li>
                                <li><Link to="/gallery" >Gallery</Link></li>
                                <li><Link to="/technology" >Technology</Link></li>
                                {/* <li><Link to="/contact" >Contact</Link></li> */}
                            </ul>
                        </nav>
                        <div className="header-btn">
                            <Link className="btn-default btn-border btn-opacity" to="/contact">
                                <span>Contact</span>
                            </Link>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>

            </header>
        )
    }
}
export default Header;