import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiMail, FiCopy } from "react-icons/fi";
import { Link } from 'react-router-dom';
// const ServiceList = [
//     {
//         icon: <FiCast />,
//         title: 'Medical Services and Product Supplies',
//         description: 'Providing professional medical staff, arranging supplies and equipment.',
//         url: '/medical-service'
//     },
//     {
//         icon: <FiLayers />,
//         title: 'Training & Education',
//         description: 'We are committed to educating and training and engage with students from many Universities and postgraduate training.',
//         url: '/traning-and-education-service'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'Digital Healthcare & Solutions',
//         description: 'Digital solutions make it easier for users to access the services they need, which can lead to better health outcomes.',
//         url: '/digital-healthcare-and-strategy-solustions-service'
//     },
//     {
//         icon: <FiMonitor />,
//         title: 'Research & Innovation',
//         description: 'Our company welcomes involvement in research studies and innovation, participating regularly in many projects.',
//         url: '/research-and-innovation-service'
//     },
//     {
//         icon: <FiMail />,
//         title: 'App Development & Tech',
//         description: 'Clinician-led development of apps supporting the healthcare industry to improve patient care and outcomes.',
//         url: '/app-development-service'
//     },
//     {
//         icon: <FiCopy />,
//         title: 'Medical Concierge & Private Healthcare',
//         description: 'Supporting medical tourism and clients requiring bespoke service for their individual needs.',
//         url: '/medical-concierge-and-private-healthcare-service'
//     },
// ]

class ServiceTwo extends Component {
    render() {
        let title = 'Our Main Services',
            description = 'Please see some of our services which we offer our customers globally.',
            subtitle = 'What we can do for you';
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {/* {ServiceList.map( (val , i) => ( */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <Link to="/medical-services-and-product-supplies">
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                <FiCast />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Medical Services and Product Supplies</h3>
                                                <p>Providing professional medical staff, arranging supplies and equipment.<br />                 <br /></p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <Link to="/traning-and-education-service">
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                <FiLayers />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Training & Education</h3>
                                                <p>We are committed to educating and training and engage with students from many Universities and postgraduate training.</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <Link to="/digital-healthcare-and-solustions">
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                <FiUsers />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Digital Healthcare & Solutions</h3>
                                                <p>Digital solutions make it easier for users to access the services they need, which can lead to better health outcomes.</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12" >
                                    <Link to="/research-and-innovation-service">
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                <FiMonitor />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Research & Innovation</h3>
                                                <p>Our company welcomes involvement in research studies and innovation, participating regularly in many projects.</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <Link to="/app-development-service">
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                <FiMail />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">App Development & Tech</h3>
                                                <p>Clinician-led development of apps supporting the healthcare industry to improve patient care and outcomes.</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12" >
                                    <Link to="/medical-concierge-and-private-healthcare-service">
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                <FiCopy />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Medical Concierge & Private Healthcare</h3>
                                                <p>Supporting medical tourism and clients requiring bespoke service for their individual needs.</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                {/* ))} */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
