// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from './component/PageScrollTop';
import HomeParticles from './home/HomeParticles';
import MainDemo from './home/MainDemo';
import DesignerPortfolio from './home/DesignerPortfolio';


// Element Layout
import Service from "./elements/Service";
import TwoSNGallery from "./elements/2SN_Gallery";
import TwoSNOurTeam from "./elements/2SN_Our_Team";
import Contact from "./elements/Contact";

import error404 from "./elements/error404";
//2SN Services
import App_Development_TechServiceDetails from "./elements/2SN_Services/App_Development_TechServiceDetails";
import DigitalHealthcare_and_Strategy_SolutionsServiceDetails from "./elements/2SN_Services/Digital_Healthcare_and_Strategy_SolutionsServiceDetails";
import MedicalConcierge_and_Private_HealthcareServiceDetails from "./elements/2SN_Services/Medical_Concierge_and_Private_HealthcareServiceDetails";
import Research_InnovationService from "./elements/2SN_Services/Research_InnovationService";
import Training_EducationService from "./elements/2SN_Services/Training_EducationService";
import MedicalServiceDetails from "./elements/2SN_Services/MedicalServiceDetails";





import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import ClientS from './component/Details_of_client/Smaeer';
import ClientSavina from './component/Details_of_client/Savina';
import MultilingualPregnancy from './elements/portfolio/MultilingualPregnancy';
// import ClientTeam from './component/Details_of_client/Team2SN';
import HospitalPortfolioDetails from './elements/portfolio/HospitalChainPortfolio';
import HealthcarePortfolioDetails from './elements/portfolio/HealthcareInsurancePortfolio';
import Primarycare from './elements/portfolio/PrimaryCare';
import FasterChecksPortfolioDetails from './elements/portfolio/FasterChecksPortfolioDetails';
import NursingPlatformPortfolioDetails from './elements/portfolio/NursingPlatformPortfolioDetails';
import PharmacistMarketplacePortfolioDetails from './elements/portfolio/PharmacistMarketplacePortfolioDetails';
import ElectronicMedicalPortfolioDetails from './elements/portfolio/ElectronicMedicalPortfolioDetails';
import DietAppPortfolioDetails from './elements/portfolio/DietAppPortfolioDetails';
import ElearningPortalPortfolioDetails from './elements/portfolio/ElearningPortalPortfolioDetails';
import DoctorAppointmentandHospitalPortfolioDetails from './elements/portfolio/DoctorAppointmentandHospitalPortfolioDetails';
import MeditationAppPortfolioDetails from './elements/portfolio/MeditationAppPortfolioDetails';
import DoctorPersonalPortfolioDetails from './elements/portfolio/DoctorPersonalPortfolioDetails';
import CookiePolicy from './elements/CookiePolicy';
import PrivacyPolicy from './elements/Privacy-Policy';
import TermsAndCondition from './elements/TermsAndCondition';
import ModernSalavery from './elements/ModernSalavery';
// import Technology from './elements/Technology';
import CookieModal from './elements/CookieModal';
import CookieConsent from 'react-cookie-consent'
import FAQ from './elements/FAQ';
import Techstack from './elements/Techstack';
import NodeJS from './techstack/NodeJs';
import DotNet from './techstack/DotNet';
import Laravel from './techstack/Laravel';
import CI4 from './techstack/CI4';
import Python from './techstack/Python.jsx';
import Angular from './techstack/Angular';
import ReactJS from './techstack/ReactJs';
import WordPress from './techstack/WordPress';
import Shopify from './techstack/Shopify';
import Flutter from './techstack/Flutter';
import Reactnative from './techstack/ReactNative';
import Java from './techstack/Java';
import Swift from './techstack/Swift';
import Kotlin from './techstack/Kotlin';
import Objectivec from './techstack/Objectivec';

class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCookieModal: false,
            showCookieConsen: false
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('acceptedCookies')) {
            // localStorage.setItem('hasVisited', true);

            this.setState({ showCookieConsen: true })
        }
    }
    handleDecline = () => {
        this.setState({ showCookieConsen: false })
        this.setState({ showCookieModal: false })
    }

    handleAcceptCookies = () => {
        localStorage.setItem('acceptedCookies', true);
        this.setState({ showCookieModal: false });
        this.setState({ showCookieConsen: false })
    }
    showcookiepolicy = () => {
        this.setState({ showCookieModal: true })
        this.setState({ showCookieConsen: false })
    }
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    {this.state.showCookieConsen && <CookieConsent onAccept={this.handleAcceptCookies} buttonText="ALLOW"
                        className="custom-modal" buttonStyle={{ background: '#ffffff', color: '#000000', padding: '10px', borderRadius: '4px' }} location='bottom' buttonClasses="btn-default btn-border btn-opacity header-button">
                        <p style={{ fontSize: '12px' }}><Link className='btn-transparent' onClick={this.showcookiepolicy} > Click to Show Cookies Policy</Link> <br />We use cookies to provide content, and improve your online experience. For more information, please review the cookies details outlined in our Privacy Notice.<br />
                            <br /> By accepting the "All Cookies" you agree to the storing of performance and targeting cookies on your device. If you do not agree to the storing of any cookies that are not strictly necessary for the functioning of the site, click on “Reject All Cookies”.
                        </p>
                        {/* <h4>When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.</h4> */}
                    </CookieConsent>}

                    {this.state.showCookieModal && <CookieModal onAccept={this.handleAcceptCookies} onDecline={this.handleDecline} />}
                    <Switch>

                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainDemo} />

                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={DesignerPortfolio} />

                        <Route exact path={`${process.env.PUBLIC_URL}/hospital-chain-management`} component={HospitalPortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/multilingual-pregnancy-app`} component={MultilingualPregnancy} />
                        <Route exact path={`${process.env.PUBLIC_URL}/healthcare-insurance-marketplace`} component={HealthcarePortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/primary-care-management`} component={Primarycare} />
                        <Route exact path={`${process.env.PUBLIC_URL}/rapid-testing-verification`} component={FasterChecksPortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/nursing-platform`} component={NursingPlatformPortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/pharmacist-marketplace`} component={PharmacistMarketplacePortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/electronic-medical-record`} component={ElectronicMedicalPortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/diet-app`} component={DietAppPortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/e-learning`} component={ElearningPortalPortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/doctor-appointment`} component={DoctorAppointmentandHospitalPortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/meditation-app`} component={MeditationAppPortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/doctor-personal-presentation`} component={DoctorPersonalPortfolioDetails} />

                        <Route exact path={`${process.env.PUBLIC_URL}/dr-sameer`} component={ClientS} />
                        <Route exact path={`${process.env.PUBLIC_URL}/mrs-savina`} component={ClientSavina} />

                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={HomeParticles} />

                        <Route exact path={`${process.env.PUBLIC_URL}/services`} component={Service} />
                        <Route exact path={`${process.env.PUBLIC_URL}/our-team`} component={TwoSNOurTeam} />
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={TwoSNGallery} />
                        <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={FAQ} />
                        <Route exact path={`${process.env.PUBLIC_URL}/technology`} component={Techstack} />
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/technology`} component={Technology} /> */}

                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />


                        <Route exact path={`${process.env.PUBLIC_URL}/node-js`} component={NodeJS} />
                        <Route exact path={`${process.env.PUBLIC_URL}/dot-net`} component={DotNet} />
                        <Route exact path={`${process.env.PUBLIC_URL}/laravel`} component={Laravel} />
                        <Route exact path={`${process.env.PUBLIC_URL}/ci4`} component={CI4} />
                        <Route exact path={`${process.env.PUBLIC_URL}/python`} component={Python} />
                        <Route exact path={`${process.env.PUBLIC_URL}/angular`} component={Angular} />
                        <Route exact path={`${process.env.PUBLIC_URL}/react-JS`} component={ReactJS} />
                        <Route exact path={`${process.env.PUBLIC_URL}/wordpress`} component={WordPress} />
                        <Route exact path={`${process.env.PUBLIC_URL}/shopify`} component={Shopify} />
                        <Route exact path={`${process.env.PUBLIC_URL}/flutter`} component={Flutter} />
                        <Route exact path={`${process.env.PUBLIC_URL}/react-native`} component={Reactnative} />
                        <Route exact path={`${process.env.PUBLIC_URL}/java`} component={Java} />
                        <Route exact path={`${process.env.PUBLIC_URL}/swift`} component={Swift} />
                        <Route exact path={`${process.env.PUBLIC_URL}/kotlin`} component={Kotlin} />
                        <Route exact path={`${process.env.PUBLIC_URL}/objective-c`} component={Objectivec} />


                        <Route exact path={`${process.env.PUBLIC_URL}/research-and-innovation-service`} component={Research_InnovationService} />
                        <Route exact path={`${process.env.PUBLIC_URL}/app-development-service`} component={App_Development_TechServiceDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/digital-healthcare-and-solustions`} component={DigitalHealthcare_and_Strategy_SolutionsServiceDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/medical-concierge-and-private-healthcare-service`} component={MedicalConcierge_and_Private_HealthcareServiceDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/traning-and-education-service`} component={Training_EducationService} />
                        <Route exact path={`${process.env.PUBLIC_URL}/medical-services-and-product-supplies`} component={MedicalServiceDetails} />


                        <Route exact path={`${process.env.PUBLIC_URL}/cookies-policy`} component={CookiePolicy} />
                        <Route exact path={`${process.env.PUBLIC_URL}/modern-slavery-act`} component={ModernSalavery} />
                        <Route exact path={`${process.env.PUBLIC_URL}/terms-condition`} component={TermsAndCondition} />
                        <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy} />

                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                        <Route component={error404} />

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();