import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";





class TermsAndCondition extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Terms & Condition || 2SN healthcare ltd' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Terms & Conditions'} image={'bg_image--23'} />
                {/* End Breadcrump Area */}
                <div className="service-area pt--60 pb--30 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    {/* <span className="subtitle">What we can do for you</span> */}
                                    <h2>Terms and conditions for Locum GP Services</h2>
                                    {/* <p>Please see some of our services which we offer our customers globally.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Blog Details */}
                <div className="rn-blog-details  pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h3>Bookings:</h3>
                                        <p>All bookings must be confirmed in written form (via email), once confirmation is received, the booking is considered a firm contact on both parties, and ac acceptance if these terms and conditions</p>
                                        <h3>Appointments:</h3>
                                        <p>All appointments must be a mumimum of 10 minutes long. The last appointment should be booked to FINISH as the end time .e.g If you have booked me to work until 6pm, the last appointment should be no later than 5.50pm</p>
                                        <h3>Extras:</h3>
                                        <p>If you would like me to see extra patients (over and above the numbers booked in) I am happy to do so, and I will charge at my usual hourly rate in 15 minute increments. I will see a maximum of 2 extra patients.</p>
                                        <h3>Home Visits:X</h3>
                                        <p>I am happy to do home visits if they are requested in advanced (at the time of the booking). These will be charged according to the time taken at my hourly rate. The charge will include travel time and writing up the notes on my return.</p>
                                        <h3>Cancellation by the practise:</h3>
                                        <span>If the practise needs to cancel my booking, you will need to inform me as soon as possible in written form (via email or fax). A cancellation fee will apply according to the notice period given for cancellation as follows:<br /></span>
                                        <span>Cancellation 31days or more before a booking, no charge.<br /></span>
                                        <span>Cancellation 15-30days before a booking, 50% of fee payable<br /></span>
                                        <span>Cancellation 15 days or less before a booking, full fee remains payable<br /></span>
                                        <h3>Cancellation by me:</h3>
                                        <p>In the unlikely event that I am unable to provide cover as booked at short notice (due to emergency), I will inform you as soon as practical, and I will endeavour to find a suitable replacement. If I am unable to do so, I will do the next booked session free of charge.</p>
                                        <h3>Payment:</h3>
                                        <p>I will invoice you for all work done within 2 weeks of providing cover (usually sooner). All invoices should be paid within 28 days via bank transfer or cheque.</p>
                                        <h3>NHS Pension:</h3>
                                        <p>Under current regulations, the practise is responsible for paying the employers contribution element for the NHS pension. I am a member of the NHS pension scheme, and will add a separate line on the invoice to show the amount payable by the practise for employers contribution. The practise will also needs to complete a Locum A form. This should be returned with the payment.</p>
                                        <p>As per my own personal ethical views. I will not be able to see any patients requesting Terminations of Pregnancy. Should any patient see me for this, I will be happy to refer them over the BPA.</p>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}




                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default TermsAndCondition;