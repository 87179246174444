import React, { Component } from "react";
// import {FiCheck } from "react-icons/fi";
import PageHelmet from "../../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/2SN";
import Footer from "../../component/footer/2SN_Footer";


class ServiceDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Digital Healthcare and Solutions' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--10" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
                                    <h2 className="title theme-gradient">Digital Healthcare and Solutions</h2>
                                    {/* <p>Fresh From The Press Discription</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/Digital_healthcare_img2.png" alt="Digital Images" />
                                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Our service</span> */}
                                                        <h2 className="title">Digital Healthcare and Strategy Solutions</h2>
                                                        <p className="description">At 2SN Healthcare, we are a team of experienced healthcare professionals working with software developers passionate about using technology and digital solutions to improve patient outcomes. Our goal is to create innovative and user-friendly technology that streamlines the healthcare process and makes it easier for patients and healthcare professionals to access the information and services they need.</p>
                                                        <p className="description">We understand that the healthcare industry is constantly evolving and that technology can play a crucial role in improving care delivery. We have a successful track record of developing thriving healthcare apps that hospitals and clinics worldwide have used. Our team of developers and healthcare professionals work closely together to ensure that our apps are technically sound and meet the needs of patients and healthcare professionals.</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Our service</span>
                                                        <h2 className="title">Providing Service</h2> */}
                                                        <p className="description">We can work with your organisation to develop custom healthcare digital solutions that meet your needs. Our process includes an initial consultation to understand your requirements, a comprehensive analysis of your current systems and processes, and the design and development of your chosen solution or proposed options. We will work closely with you throughout the journey to ensure that the final product meets your expectations.</p>
                                                        <p className="description">Our joint vision is to deliver improved patient outcomes: Our goal with digital solutions is to make it easier for users to access the services they need, which can lead to better health outcomes. By using technology to automate specific processes and improve communication, digital solutions can help reduce costs for your organisation. Apps can streamline the healthcare process, making it easier to achieve a desired goal.</p>
                                                        <p className="description">Our experience, expertise in healthcare digital solution development, and passion for improving patient outcomes make us the ideal partner for organisations. We embrace the opportunity to work with you to create custom healthcare solutions that meet your needs. Our team will work with you to create a project timeline and budget that meets your needs and finances.</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/Digital_healthcare_img3.png" alt="Digital 2 Images" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;