import React from 'react';
// import { Link } from 'react-router-dom';
// import PageHelmet from "../component/common/Helmet";
// import Breadcrumb from "../elements/common/Breadcrumb";
// import ScrollToTop from 'react-scroll-up';
// import { FiChevronUp } from "react-icons/fi";
// import Header from "../component/header/Header";
// import Footer from "../component/footer/Footer";
import Slider from "react-slick";
// import PortfolioList from "../elements/portfolio/PortfolioList";
import { slickDot } from "../page-demo/script";

const Portfolio_image = <img src="/assets/images/flag/country_01.png" alt="india flag" />;
const Portfolio_image2 = <img src="/assets/images/flag/country_02.png" alt="Client Partner" />;
const Portfolio_image3 = <img src="/assets/images/flag/country_03.png" alt="Client Partner" />;
const Portfolio_image4 = <img src="/assets/images/flag/country_04.png" alt="Client Partner" />;
const Portfolio_image5 = <img src="/assets/images/flag/country_05.png" alt="Client Partner" />;
const Portfolio_image6 = <img src="/assets/images/flag/country_06.png" alt="Client Partner" />;
const Portfolio_image7 = <img src="/assets/images/flag/country_07.png" alt="Client Partner" />;
const Portfolio_image8 = <img src="/assets/images/flag/country_08.png" alt="Client Partner" />;
const Portfolio_image9 = <img src="/assets/images/flag/country_09.png" alt="Client Partner" />;
const Portfolio_image10 = <img src="/assets/images/flag/country_10.png" alt="Client Partner" />;
const Portfolio_image11 = <img src="/assets/images/flag/country_11.png" alt="Client Partner" />;
const Portfolio_image12 = <img src="/assets/images/flag/country_12.png" alt="Client Partner" />;
const Portfolio_image13 = <img src="/assets/images/flag/country_13.png" alt="Client Partner" />;
const Portfolio_image14 = <img src="/assets/images/flag/country_14.png" alt="Client Partner" />;
const Portfolio_image15 = <img src="/assets/images/flag/country_15.png" alt="Client Partner" />;
const Portfolio_image16 = <img src="/assets/images/flag/country_16.png" alt="Client Partner" />;
const Portfolio_image17 = <img src="/assets/images/flag/country_17.png" alt="Client Partner" />;
// const Portfolio_image18 = <img src="/assets/images/flag/country_18.png" alt="Client Partner" />;
// const Portfolio_image19 = <img src="/assets/images/flag/country_19.png" alt="Client Partner" />;
// const Portfolio_image20 = <img src="/assets/images/flag/country_20.png" alt="Client Partner" />;
// const Portfolio_image21 = <img src="/assets/images/flag/country_21.png" alt="Client Partner" />;
// const Portfolio_image22 = <img src="/assets/images/flag/country_22.png" alt="Client Partner" />;
// const Portfolio_image23 = <img src="/assets/images/flag/country_23.png" alt="Client Partner" />;
// const Portfolio_image24 = <img src="/assets/images/flag/country_24.png" alt="Client Partner" />;
// const Portfolio_image25 = <img src="/assets/images/flag/country_25.png" alt="Client Partner" />;
// const Portfolio_image26 = <img src="/assets/images/flag/country_26.png" alt="Client Partner" />;
// const Portfolio_image27 = <img src="/assets/images/flag/country_27.png" alt="Client Partner" />;
// const Portfolio_image28 = <img src="/assets/images/flag/country_28.png" alt="Client Partner" />;


const list = [
    {
        image: Portfolio_image,

    },
    {
        image: Portfolio_image2,

    },
    {
        image: Portfolio_image3,

    },
    {
        image: Portfolio_image4,

    },
    {
        image: Portfolio_image5,

    },
    {
        image: Portfolio_image6,

    },
    {
        image: Portfolio_image7,

    },
    {
        image: Portfolio_image8,

    },
    {
        image: Portfolio_image9,

    },
    {
        image: Portfolio_image10,

    },
    {
        image: Portfolio_image11,

    },
    {
        image: Portfolio_image12,

    },
    {
        image: Portfolio_image13,

    },
    {
        image: Portfolio_image14,

    },
    {
        image: Portfolio_image15,

    },
    {
        image: Portfolio_image16,

    },
    {
        image: Portfolio_image17,

    },
    // {
    //     image: Portfolio_image18,

    // },
    // {
    //     image: Portfolio_image19,

    // },
    // {
    //     image: Portfolio_image20,

    // },
    // {
    //     image: Portfolio_image21,

    // },
    // {
    //     image: Portfolio_image22,

    // },
    // {
    //     image: Portfolio_image23,

    // },
    // {
    //     image: Portfolio_image24,

    // },
    // {
    //     image: Portfolio_image25,

    // },
    // {
    //     image: Portfolio_image26,

    // },
    // {
    //     image: Portfolio_image27,

    // },
    // {
    //     image: Portfolio_image28,

    // }
]

// const PortfolioList2 = [
//     {
//         image: 'image-1',
//         category: 'Development',
//         title: ' Getting tickets to the big show'
//     },
//     {
//         image: 'image-2',
//         category: 'Development',
//         title: ' Getting tickets to the big show'
//     },
//     {
//         image: 'image-3',
//         category: 'Development',
//         title: ' Getting tickets to the big show'
//     },
//     {
//         image: 'image-4',
//         category: 'Development',
//         title: ' Getting tickets to the big show'
//     },
//     {
//         image: 'image-3',
//         category: 'Development',
//         title: ' Getting tickets to the big show'
//     },
//     {
//         image: 'image-4',
//         category: 'Development',
//         title: ' Getting tickets to the big show'
//     }
// ]

const Country_flag = () => {
    return (
        <>



            {/* Start Portfolio Area */}
            <div className="">
                <div className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12" >
                                <div className="slick-space-gutter--15  ">
                                    <Slider {...slickDot} autoplaySpeed='20' style={{ display: 'none' }} autoplay>
                                        {list.map((value, index) => (
                                            <div className="single_im_portfolio" key={index}>
                                                <div className="">
                                                    <div className="">
                                                        <div className="" style={{ width: "65%" }}>
                                                            {value.image}
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* End Page Wrapper  */}

            {/* Start Back To Top */}

        </>
    )
}

export default Country_flag;