import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
// import Testimonial from "./Testimonial";
// import BrandTwo from "./BrandTwo";
// import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";
// import AboutComp from "../component/HomeLayout/homeOne/About";
import { Link } from "react-router-dom";

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Our Team || 2SN healthcare ltd' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="2SN_logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Our Team'} image={'bg_image--24'} />
                {/* End Breadcrump Area */}




                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25">
                                    <span className="subtitle">Leading team members</span>
                                    <h2 className="title">Our team </h2>
                                    <p className="description">Introducing our key team members, responsible for continual growth and operations of our company.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">

                            {/* Start Single Team  */}
                            {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12"> */}
                            <div className="col-lg-3 col-md-4 col-sm-6 col-8">
                                <Link to="/dr-sameer" >
                                    <div className="team">
                                        <div className="thumbnail">

                                            <img className="" src="/assets/images/team/team-01.png" alt="Dr.Sameer Nakedar" />

                                        </div>
                                        <div className="content">
                                            <h4 className="title">Dr Sameer Nakedar</h4>
                                            <p className="designation">Co-founder and CEO</p>
                                        </div>
                                        {/* <Link to="/dr-sameer"></Link> */}
                                        {/* <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul> */}
                                    </div>
                                </Link>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12"> */}
                            <div className="col-lg-3 col-md-4 col-sm-6 col-8">
                                <Link to="/mrs-savina" >
                                    <div className="team">
                                        <div className="thumbnail">
                                            <img className="" src="/assets/images/team/team-02.png" alt="Blog Images" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Savina Nakedar</h4>
                                            <p className="designation">Co-founder and Head of Strategy</p>
                                        </div>
                                        {/* <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul> */}
                                    </div>
                                </Link>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12"> */}
                            <div className="col-lg-3 col-md-4 col-sm-6 col-8">
                                {/* <Link  to="/about" > */}
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="" src="/assets/images/team/team-03.jpg" alt="Blog Images" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Patricia Edwards</h4>
                                        <p className="designation">Marketing Executive</p>
                                    </div>
                                    {/* <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul> */}
                                </div>
                                {/* </Link> */}
                            </div>
                            {/* End Single Team  */}

                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Testimonial Area */}
                {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div> */}
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Top clients</span>
                                    <h2 className="title">We worked with brands.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default About