import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";
// import { Link } from 'react-router-dom';





class ModernSalavery extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Modern Slavery Act Policy ' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Modern Slavery Act Policy'} image={'bg_image--11'} />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area pb--120 pt--60 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    {/* <h4>Modern Slavery Act Policy</h4>
                                    <p>2SN Healthcare Limited is committed to preventing modern slavery and human trafficking in all its forms, both within the company and throughout its supply chains.</p>
                                    <p>We believe that it is our responsibility to take a proactive approach to identifying and mitigating the risks of slavery and human trafficking in our business and supply chains. To this end, we have implemented the following measures:</p>
                                    <p>Due Diligence: We conduct due diligence on all of our suppliers, sub-contractors, and business partners to assess the risk of slavery and human trafficking.</p>
                                    <p>Supplier Code of Conduct: We have developed a Supplier Code of Conduct which sets out our expectations of suppliers and partners with regards to human rights, including the prohibition of slavery and human trafficking.</p>
                                    <p>Training: We have provided training to our employees on the subject of modern slavery and human trafficking, so that they are able to identify and report any concerns they may have.</p>
                                    <p>Monitoring and Review: We regularly monitor and review our supply chains to ensure that our suppliers and partners are compliant with our Supplier Code of Conduct.</p>
                                    <p>This Modern Slavery Act Policy will be reviewed annually to ensure that it remains relevant and up-to-date.</p> */}
                                    <h4>Policy Statement</h4>
                                    <p>Modern slavery is a crime and a violation of fundamental human rights. It takes
                                        various forms, such as slavery, servitude, forced and compulsory labour, and
                                        human trafficking, all of which have in common the deprivation of a person’s
                                        liberty by another in order to exploit them for personal or commercial gain. We
                                        are committed to acting ethically and with integrity in our business dealings and
                                        relationships and to implementing and enforcing systems and controls to ensure
                                        modern slavery is not taking place in our own business or in our supply chains.</p>
                                    <p>We are also committed to ensuring there is transparency in our own business and
                                        in our approach to tackling modern slavery throughout our supply chains,
                                        consistent with disclosure obligations we may have under applicable law. We
                                        expect the same standards from our contractors, suppliers, and other business
                                        partners, and as part of our contracting processes, we include specific
                                        prohibitions against the use of forced, compulsory or trafficked labour, or anyone
                                        held in slavery or servitude, whether adults or children.</p>
                                    <h4>Who Must Comply With This Policy?</h4>
                                    <p>This policy applies to all persons working for us or on our behalf in any capacity,
                                        including employees at all levels, directors, officers, agency workers, seconded
                                        workers, volunteers, interns, agents, contractors, external consultants, third-party
                                        representatives and business partners.</p>
                                    <h4>Who Is Responsible For This Policy?</h4>
                                    <p>The Accountable Manager has overall responsibility for ensuring this policy
                                        complies with our legal and ethical obligations, and that all those under our
                                        control comply with it. He has primary responsibility for implementing this policy,
                                        monitoring its use and effectiveness, dealing with any queries about it, and
                                        reviewing internal control systems and procedures to ensure they are effective in
                                        countering modern slavery and human trafficking.</p>
                                    <p>Management at all levels are responsible for ensuring those reporting to them
                                        understand and comply with this policy. This policy will be reviewed regularly and
                                        we may amend it at any time to update with best practices and processes.</p>
                                    <h4>Your Compliance With This Policy</h4>
                                    <p>You must ensure that you read, understand and comply with this policy.
                                        Preventing, detecting, and reporting modern slavery in any part of our business or
                                        supply chains is the responsibility of those working for 2SN HEALTHCARE LTD and under our control. You are required to avoid any activity that might lead to, or
                                        suggest, a breach of this policy.</p>
                                    <h4>How And When To Notify In Case Of Non-Compliance</h4>
                                    <p>If you believe or suspect that a breach of this policy has occurred, or may occur in
                                        the future, you must inform your Manager as soon as possible.</p>
                                    <p>You are encouraged to raise concerns about any issue or suspicion of modern
                                        slavery in any parts of our business or supply chains at the earliest possible stage.</p>
                                    <p>If you are unsure about whether a particular act, the treatment of workers more
                                        generally, or their working conditions within any tier of our supply chains
                                        constitutes any of the various forms of modern slavery, raise it with your Manager or directly with the Accountable Manager by emailing: {" "}<span className="im_address"><a className="link  im-hover " style={{ marginLeft: '0px', fontWeight: 'normal' }} href="mailto:info@2snhealthcare.co.uk">info@2snhealthcare.co.uk</a></span> </p>
                                    <p>We aim to encourage openness and will support anyone who raises genuine
                                        concerns in good faith under this policy, even if they turn out to be mistaken. We
                                        are committed to ensuring no one suffers any detrimental treatment as a result
                                        of reporting in good faith their suspicion that modern slavery of whatever form is
                                        or may be taking place in any part of our own business or in any of our supply
                                        chains. Detrimental treatment includes dismissal, disciplinary action, threats or
                                        other unfavourable treatment connected with raising a concern. If you believe
                                        that you have suffered any such treatment, you should inform the Accountable
                                        Manager directly immediately.</p>
                                    <h4>Communication And Awareness Of This Policy</h4>
                                    <p>Training on this policy, and on the risk our business faces from modern slavery,
                                        forms part of the regular training for our employees.
                                        Our commitment to addressing issues of modern slavery in our business and
                                        supply chains should be communicated to all suppliers, contractors and business
                                        partners at the outset of our business relationship with them and reinforced as
                                        appropriate thereafter.</p>
                                    <h4>Breaches Of This Policy</h4>
                                    <p>Any employee who breaches this policy may face disciplinary action, which could
                                        result in dismissal for misconduct or gross misconduct.</p>
                                    <p>We may terminate our relationship with other individuals and organisations
                                        working on our behalf if they breach this policy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default ModernSalavery;