import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/2SN";
import Footer from "../../component/footer/2SN_Footer";
// import PortfolioMasonry from './PortfolioMasonry';
import { FiCheck } from "react-icons/fi";
// import CourierPortfolioMasonry from "./CourierMasonry";
// import HospitalMasonry from "./HospitalMasonry";
// import HealthcareMasonry from "./HealthcareMasonry";
// import HotelMasonry from "./HotelMasonry";
// import MultingualPregnancyMasonry from "./MultingualPregnancyMasonry";
import MultilingualMasonry from "./MultilingualMasonry";

// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]
const Portfolio_image = <img src="/assets/images/portfolio/portfolio-1.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-2.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-3.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-4.jpg" alt="React Creative Agency" />;
// const list = [
//     {
//         image: Portfolio_image,
//         category: 'Development',
//         title: 'Web Design',
//         description: 'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.'
//     },
//     {
//         image: Portfolio_image2,
//         category: 'Product Design',
//         title: 'App Development',
//         description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
//     },
//     {
//         image: Portfolio_image3,
//         category: 'Design',
//         title: 'Photoshop',
//         description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
//     },
//     {
//         image: Portfolio_image4,
//         category: 'Shop',
//         title: 'Woocommerce',
//         description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
//     },]

class MultilingualPregnancy extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Multilingual Pregnency || 2SN healthcare ltd  ' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--35" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Multilingual Pregnancy App</h2>
                                    <p>Android / iOS Application</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Android / iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Healthcare Informational App</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, CodeIgniter, Flutter</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title theme-gradient">Multilingual Pregnancy App</h3>
                                        <p>A multilingual mobile application that will help to solve common health conditions or problems a woman may experience during her pregnancy period. The app has a category of various topics which can also be found by keywords, and portrays articles that focus on health issues and all the informative content that can aid pregnant women. It helps the users to get more information about each trimester and all the dos and don’ts of the pregnancy cycle. The articles consist of images and videos for better understanding. The admin has the authority to add, edit, delete the articles.</p>
                                        {/* <p>This electronic money issuance activity promotes banking and financial inclusion, through access to financial services for all socio-professional categories.</p>
                                      <p>Scholarship Distribution System offers quality services allowing its customers to carry out secure, simple and fast transactions.</p> */}
                                        <h4 className="title">Visitor</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Multiple languages.</li>
                                            <li><FiCheck />Category wise articles.</li>
                                            <li><FiCheck />Search by keywords.</li>
                                            <li><FiCheck />Read articles in your language.</li>
                                            <li><FiCheck />Searchable article contents.</li>
                                            <li><FiCheck />Detailed article page with title, image, video, date and description.</li>
                                            <li><FiCheck />Contact via call/ email/ WhatsApp</li>
                                        </ul>

                                        <h4 className="title">Admin</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Statistics of categories, articles and users.</li>
                                            <li><FiCheck />Manage Categories.</li>
                                            <li><FiCheck />Search by keywords.</li>
                                            <li><FiCheck />Add Articles.</li>
                                            <li><FiCheck />Edit or delete articles.</li>

                                        </ul>



                                        {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>

                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="wrapper plr--30">
                        <div className="row">
                            <MultilingualMasonry item="4" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
                    </div>
                    {/* End Portfolio Area  */}
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default MultilingualPregnancy;
