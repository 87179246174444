import React, { Component } from "react";
import { FiCast } from "react-icons/fi";
import { FaMobileAlt, FaClinicMedical } from "react-icons/fa";


const ServiceList = [
    {
        icon: <FaClinicMedical />,
        title: 'Medical Services',
        description: 'From preventive care to specialized treatments, we are committed to providing personalized care and ensuring your overall health and wellness.'
    },
    {
        icon: <FiCast />,
        title: 'Digital Technology Solutions',
        description: 'From telemedicine to electronic medical records, we use the latest technology to improve patient experience and provide a seamless healthcare experience.'
    },
    {
        icon: <FaMobileAlt />,
        title: 'App Development',
        description: 'Our team of experts leverages the latest technologies to create innovative and user-friendly apps that meet your specific needs and goals.'
    },
]
class ServiceOne extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row row--25">
                    {ServiceList.map((val, i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon" >
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p style={{ textAlign: 'left', paddingLeft: '40px' }}>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;