import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";

class WordPress extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='WordPress || 2SN Healthcare ltd' pageUrl='/wordpress' metaTitle="Wordpress" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Get a professional and fully-functional WordPress website with 2SN Healthcare ltd. Our team of skilled developers specializes in creating highly customizable and visually-appealing websites." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--25" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">WordPress</h2>
                                    <p>One of the most effective CMS ever with simple functionality, quick and easy installation, variety of themes and multiple plugins features.    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        {/* <h4 className="tilte">One Full</h4> */}
                                        <p>WordPress is one of the most famous and user-friendly development technologies that can be customized as per the requirement of a website or that of a business.</p>
                                        <p>2SN Healthcare ltd has been building attractive and highly customizable WordPress sites. We have served a wide range of customers with our advanced site development services and have built impressive websites developed on this technology.</p>
                                        <p>We are efficient in providing solutions that help our clients realize their online potential. We take massive care at developing websites. You can get your WordPress site customized to appropriate your varying business requirements and specifications.</p>
                                        <p>We assist you build dynamic and robust WordPress sites that connect easily with the audiences and help you reach out to your business prospects.</p>
                                        <p>Our experienced team of WordPress designers and developers help the clients select themes and plug-ins as per their choice and requirement.</p>
                                        <h4 className="title">Our Wordpress solutions provide the following:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Tailored CMS development.</li>
                                            <li><FiCheck />Wordpress plugin development.</li>
                                            <li><FiCheck />Responsive Wordpress theme development.</li>
                                            <li><FiCheck />Wordpress theme customization.</li>
                                            <li><FiCheck />Wordpress CMS installation and configuration.</li>
                                            <li><FiCheck />Wordpress maintenance services.</li>


                                        </ul>
                                        <h4 className="title">SALIENT FEATURES OF OUR WORDPRESS SITES</h4>

                                        <ul className="list-style--1">
                                            <li><FiCheck />Highly Customizable</li>
                                            <li><FiCheck />Simple Functionality</li>
                                            <li><FiCheck />Easy & Quick Installation</li>
                                            <li><FiCheck />Thousands of Plug-ins</li>
                                            <li><FiCheck />Plethora of Themes</li>
                                            <li><FiCheck />Easy to maintain and update</li>
                                            <li><FiCheck />SEO-friendly</li>
                                            <li><FiCheck />Custom links</li>
                                            <li><FiCheck />Drag-n-drop menu</li>
                                            <li><FiCheck />Easy website expansion</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default WordPress;