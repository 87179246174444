import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/2SN";
import Footer from "../component/footer/2SN_Footer";
// import { slideSlick } from "../page-demo/script";
// import Slider from "react-slick";


class Java extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Java || 2SN Healthcare ltd' pageUrl='/java' metaTitle="Java" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Develop high-performance and scalable applications with Java and the expertise of 2SN Healthcare ltd." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--25" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Java</h2>
                                    <p>Custom oriented and pioneering methodologies facilitate all the requisite functionality and eliminate any failures of the product.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p>The Java Development team at 2SN Healthcare ltd deliver multifaceted and large applications is no more difficult task as we guarantee our client for our Java Developers’ thoroughly practical industry knowledge and the appropriate delivery of software products. Outsourcing Java Development with us offers you the breathe-easy feeling for every stage of SDLC from project specification to project support & upholding.</p>
                                        <p>2SN Healthcare ltd - Android software development team creates unique and creative apps from scratch using the best strategy, design and technical expertise. With years of experience in Android app programming, we have delivered several apps successfully.</p>
                                        <p>Java is a general purpose programming language which can be separated into 3 sections- JDK, J2EE and J2ME. The language is prepared with a variety of advanced development tools for Java Web Application Development.</p>
                                        <p>At 2SN Healthcare ltd we are Hire Dedicated Java Developers to work under your supervision and support your Java team accruing significant skills and commitment entirely for your project and business requirements.</p>

                                        <h4 className="title">OUR PROVIDING FOR JAVA APPLICATION DEVELOPMENT</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Custom Application Development.</li>
                                            <li><FiCheck />Business Application Development.</li>
                                            <li><FiCheck />Messaging Application Development & Communication.</li>
                                            <li><FiCheck />Game application Development.</li>
                                            <li><FiCheck />Rich API application Development.</li>
                                            <li><FiCheck />Application integration services.</li>
                                            <li><FiCheck />Social networking application Development.</li>
                                            <li><FiCheck />Application Development consulting services.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Java;